import React, { createContext, useReducer } from 'react';

export const CollaborationsCtx = createContext();

const initialState = {
  myOpportunities: [],
  collaboration: {},
  fullCount: 0,
  curentIndicatorsChart: 'proposals',
  currenciesHandled: false,
  fullUnread: null,
  listener: null,
  selectedId: null,
  isLoading: false,
  reviewLink: null,
  myCollaborations: [],
  activeTab: 'Proposal',
  chatEvent: 0,
  clicks: {},
  hasLinks: false,
  creatorsUnlockDates: {},
  pipelines: {},
  selectedCampaign: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MY_OPPORTUNITIES':
      return {
        ...state,
        myOpportunities: action.payload,
      };
    case 'SET_SELECTED_CAMPAIGN':
      return {
        ...state,
        selectedCampaign: action.payload,
      };
    case 'SET_COLLABORATION':
      return {
        ...state,
        collaboration: action.payload,
      };
    case 'SET_FULL_COUNT':
      return {
        ...state,
        fullCount: action.payload,
      };
    case 'SET_PIPELINES':
      return {
        ...state,
        pipelines: action.payload,
      };
    case 'SET_FULL_UNREAD':
      return {
        ...state,
        fullUnread: action.payload,
      };
    case 'CURRENCIES_HANDLED':
      return {
        ...state,
        currenciesHandled: action.payload,
      };
    case 'RESET_COLLABORATION':
      return {
        ...state,
        collaboration: {},
      };
    case 'SET_SELECTED_ID':
      return {
        ...state,
        selectedId: action.payload,
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_REVIEW_LINK':
      return {
        ...state,
        reviewLink: action.payload,
      };
    case 'SET_MY_COLLABORATIONS':
      return {
        ...state,
        myCollaborations: action.payload,
      };
    case 'SET_CREATOR_UNLOCK_DATES':
      return {
        ...state,
        creatorsUnlockDates: action.payload,
      };
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
      };
    case 'SET_REFRESH_MESSAGES':
      return {
        ...state,
        chatEvent: action.payload,
      };
    case 'SET_CLICKS':
      return {
        ...state,
        clicks: action.payload,
      };
    case 'SET_HAS_LINKS':
      return {
        ...state,
        hasLinks: action.payload,
      };
    case 'RESET_STORE':
      return initialState;
    default:
      return state;
  }
}

export function CollaborationsCtxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <CollaborationsCtx.Provider value={value}>{children}</CollaborationsCtx.Provider>;
}
