import React, { useContext, useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import ForgotPassword from '../../LoginSignupPage/components/ForgotPassword';
import LoginSignupPage from 'pages/LoginSignup/LoginSignupPage/LoginSignupPage';

import '../../login-signup.scss';
import { ResultsCtx } from 'pages/Results/contexts/results-ctx';

const LoginSignupModal = ({ openModal, closeModal, visible, loginSignupModalType, isHomeHeader }) => {
  const { t } = useTranslation('common');
  const [isForgotVisible, setForgotVisible] = useState(false);

  const { dispatch } = useContext(ResultsCtx);

  const toggleForgot = () => {
    setForgotVisible(!isForgotVisible);
  };

  const getModalTitle = () => {
    if (loginSignupModalType === 'login') {
      return t('login_page.title');
    } else {
      return t('signup_page.title');
    }
  };

  return (
    <>
      <Modal
        title={getModalTitle()}
        width={420}
        footer={null}
        onCancel={closeModal}
        open={visible}
        mask={isHomeHeader ? false : true}
        maskClosable={isHomeHeader ? false : true}
        closable={isHomeHeader ? false : true}
        zIndex={isHomeHeader ? 0 : 10000}
        className="login-modal"
        centered
      >
        <LoginSignupPage
          toggleVisibility={() => {
            dispatch({ type: 'CLOSE_LOGIN_SIGNUP_MODAL' });
            closeModal();
          }}
          isLoginPage={loginSignupModalType === 'login'}
          isSignupPage={loginSignupModalType !== 'login'}
          toggleLogin={openModal}
          isModal
        />
      </Modal>

      <ForgotPassword visible={isForgotVisible} toggleVisibility={toggleForgot} />
    </>
  );
};

export default React.memo(LoginSignupModal);
