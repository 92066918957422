import React, { createContext, useReducer } from 'react';

export const ResultsCtx = createContext();

const initialState = {
  loginSignupModalType: null,
  isLoginSignupModalVisible: false,
  isMobileFilterOpen: false,
  hasReachedSearchLimit: false,
  hasReachedOutreachLimit: false,
  hasReachedProfileLimit: false,
  hasReachedAnalyticsLimit: false,
  remainingAnalytics: null,
  remainingSearches: null,
  remainingProfiles: null,
  remainingOutreach: null,
  remainingMedia: null,
  limitsFetched: false,
  isOutreachModalVisible: false,
  opportunities: [],
  searchOverlay: false,
  manuallyOpenTagSearch: false,
  hasResults: false,
  results: [],
  lastQuery: '',
  selectedRowKeys: [],
  selectedAccounts: [],
  searchValue: null,
  locationValue: null,
  filtersCounter: null,
  isCounterLoading: false,
  clearedAll: false,
  page: null,
  downloadResultsModalVisible: false,
  saveResultsModalVisible: false,
  totalResults: 0,
  resultsPerPage: 0,
  totalSearchResultsPageCount: 0,
  maxResultsPagesToDownload: 0,
  rowClicked: null,
  isSearchVisible: false,
  selectedIndex: null,
  searchListInputValue: '',
  isFiltersDrawerOpen: false,
  isPlaceholderVisible: true,
};

function reducer(state, action) {
  const { type = null, payload = null } = action;
  switch (type) {
    case 'OPEN_LOGIN_SIGNUP_MODAL':
      return {
        ...state,
        isLoginSignupModalVisible: true,
      };
    case 'CLOSE_LOGIN_SIGNUP_MODAL':
      return {
        ...state,
        isLoginSignupModalVisible: false,
      };
    case 'SELECT_INDEX':
      return {
        ...state,
        selectedIndex: payload,
      };
    case 'OPEN_MOBILE_FILTER_MODAL':
      return {
        ...state,
        isMobileFilterOpen: true,
      };
    case 'MODIFY_SEARCH_LIST_INPUT_VALUE':
      return {
        ...state,
        searchListInputValue: payload,
      };
    case 'CLOSE_MOBILE_FILTER_MODAL':
      return {
        ...state,
        isMobileFilterOpen: false,
      };
    case 'OPEN_OUTREACH_MODAL':
      return {
        ...state,
        isOutreachModalVisible: true,
      };
    case 'CLOSE_OUTREACH_MODAL':
      return {
        ...state,
        isOutreachModalVisible: false,
      };
    case 'SET_LOGIN_SIGNUP_MODAL_TYPE':
      return {
        ...state,
        loginSignupModalType: payload,
      };
    case 'SET_HAS_REACHED_SEARCH_LIMIT':
      return {
        ...state,
        hasReachedSearchLimit: true,
      };
    case 'SET_ROW_CLICKED':
      return {
        ...state,
        rowClicked: payload,
      };
    case 'SET_HAS_REACHED_PROFILE_LIMIT':
      return {
        ...state,
        hasReachedProfileLimit: true,
      };
    case 'SET_HAS_REACHED_OUTREACH_LIMIT':
      return {
        ...state,
        hasReachedOutreachLimit: true,
      };
    case 'SET_HAS_REACHED_ANALYTICS_LIMIT':
      return {
        ...state,
        hasReachedAnalyticsLimit: true,
      };
    case 'SET_REMAINING_ANALYTICS':
      return {
        ...state,
        remainingAnalytics: payload,
      };
    case 'SET_REMAINING_SEARCHES':
      return {
        ...state,
        remainingSearches: payload,
      };
    case 'SET_REMAINING_PROFILES':
      return {
        ...state,
        remainingProfiles: payload,
      };
    case 'SET_REMAINING_OUTREACH':
      return {
        ...state,
        remainingOutreach: payload,
      };
    case 'SET_LIMITS_DATA':
      return {
        ...state,
        remainingAnalytics: payload.remainingAnalytics ?? state.remainingAnalytics,
        remainingSearches: payload.remainingSearches ?? state.remainingSearches,
        remainingProfiles: payload.remainingProfiles ?? state.remainingProfiles,
        remainingOutreach: payload.remainingOutreach ?? state.remainingOutreach,
        remainingMedia: payload.remainingMedia ?? state.remainingMedia,
        hasReachedAnalyticsLimit: payload.hasReachedAnalyticsLimit,
        hasReachedSearchLimit: payload.hasReachedSearchLimit,
        hasReachedProfileLimit: payload.hasReachedProfileLimit,
        hasReachedOutreachLimit: payload.hasReachedOutreachLimit,
        limitsFetched: true,
      };
    case 'GET_OPPORTUNITIES':
      return {
        ...state,
        opportunities: payload,
      };
    case 'TOGGLE_SEARCH_OVERLAY':
      return {
        ...state,
        searchOverlay: payload,
      };
    case 'TOGGLE_IS_SEARCH_VISIBLE':
      return {
        ...state,
        isSearchVisible: payload,
      };
    case 'TOGGLE_FILTERS_OVERLAY':
      return {
        ...state,
        filtersOverlay: payload,
      };
    case 'SET_MANUALLY_OPEN_TAG_SEARCH':
      return {
        ...state,
        manuallyOpenTagSearch: payload,
      };
    case 'SET_HAS_RESULTS':
      return {
        ...state,
        hasResults: payload,
      };
    case 'LAST_QUERY':
      return {
        ...state,
        lastQuery: payload,
      };
    case 'RESULTS':
      return {
        ...state,
        results: payload,
      };
    case 'TOTAL_RESULTS':
      const { resultsPerPage, totalResultsPagesCount, totalResults, maxResultsPagesToDownload } = payload;
      return {
        ...state,
        totalResults,
        resultsPerPage,
        totalResultsPagesCount,
        maxResultsPagesToDownload,
      };
    case 'PAGE':
      return {
        ...state,
        page: payload,
      };
    case 'SELECT_RESULTS':
      return {
        ...state,
        selectedRowKeys: payload,
      };
    case 'SELECT_ACCOUNTS':
      return {
        ...state,
        selectedAccounts: payload,
      };
    case 'SET_SEARCH_VALUE':
      return {
        ...state,
        searchValue: payload,
      };
    case 'SET_LOCATION_VALUE':
      return {
        ...state,
        locationValue: payload,
      };
    case 'SET_FILTERS_COUNTER':
      return {
        ...state,
        filtersCounter: payload,
      };
    case 'HAS_CLEARED_ALL':
      return {
        ...state,
        clearedAll: payload,
      };
    case 'SET_COUNTER_LOADING':
      return {
        ...state,
        isCounterLoading: payload,
      };
    case 'TOGGLE_DOWNLOAD_RESULTS_MODAL':
      return {
        ...state,
        downloadResultsModalVisible: !state.downloadResultsModalVisible,
      };
    case 'TOGGLE_SAVE_SEARCH_TO_LIST_MODAL':
      return {
        ...state,
        saveResultsModalVisible: !state.saveResultsModalVisible,
      };
    case 'OPEN_FILTER_MODAL':
      return {
        ...state,
        isFiltersDrawerOpen: payload,
      };
    case 'SHOW_PLACEHOLDER':
      return {
        ...state,
        isPlaceholderVisible: payload,
      };
    case 'RESET_STORE':
      return initialState;
    default:
      return state;
  }
}

export function ResultsCtxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <ResultsCtx.Provider value={value}>{children}</ResultsCtx.Provider>;
}
