import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import defaultRedirectUrl from 'helpers/defaultRedirectUrl';

export default function useAfterAuthRedirections() {
  const navigate = useNavigate();
  const location = useLocation();

  const afterAuthRedirect = useCallback(
    (accountType) => {
      const defaultRedirect = defaultRedirectUrl(accountType);
      const redirectionLocation = JSON.parse(localStorage.getItem('redirectionLocation'));
      const redirectTo = redirectionLocation ?? defaultRedirect;
      navigate(redirectTo);
      localStorage.removeItem('redirectionLocation');
    },
    [navigate],
  );

  const setAfterAuthRedirectionLocation = useCallback(() => {
    localStorage.setItem('redirectionLocation', JSON.stringify(location));
  }, [location]);

  return { afterAuthRedirect, setAfterAuthRedirectionLocation };
}
