import { getAbTestCookie } from 'utils/abTest';

// eslint-disable-next-line import/no-anonymous-default-export
export default (url, requestOptions) => {
  try {
    const testCookie = JSON.stringify(getAbTestCookie());
    if (!requestOptions.headers) requestOptions.headers = {}
    if (testCookie) {
      requestOptions.headers.ab_tests = testCookie
    }
    requestOptions.headers['x-path'] = window.location.pathname;

  } catch (e) {
    console.error(e)
  }

  return fetch(url, requestOptions);
};
