 export default (num, options = {}) => { // eslint-disable-line import/no-anonymous-default-export
  const { removeDecimals = false } = options;
  if (num === 0) return 0;
  if (!num) return '-';
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(removeDecimals ? 0 : 1).replace(/\.0$/, '')}B`;
  }
  if (num >= 100000000) {
    return `${(num / 1000000).toFixed(0).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(removeDecimals ? 0 : 1).replace(/\.0$/, '')}M`;
  }
  if (num >= 100000) {
    return `${(num / 1000).toFixed(0).replace(/\.0$/, '')}K`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(removeDecimals ? 0 : 1).replace(/\.0$/, '')}K`;
  }
  return typeof num === 'number' ? num.toFixed(removeDecimals ? 0 : 2).replace(/[.,]00$/, '') : num;
};