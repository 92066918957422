import { Button, Empty } from 'antd';
import React from 'react';

import NotFoundImg from 'images/blankslates/404.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
  const { t } = useTranslation('common');

  const navigate = useNavigate();

  return (
    <Empty
      image={
        <div className="blankslate-icon">
          <img src={NotFoundImg} alt="" />
        </div>
      }
      description={
        <div className="blankslate-description">
          {/* <h4 className="blankslate-description-title">{t('errors_messages.404')}</h4> */}
          <p className="blankslate-description-text">{t('errors_messages.404')}</p>

          <Button
            type="primary"
            onClick={() => {
              navigate('/');
            }}
          >
            {t('errors_messages.GoHomepage')}
          </Button>
        </div>
      }
      style={{
        maxWidth: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 200,
      }}
    />
  );
}
