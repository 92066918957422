import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import ForceReload from "components/ForceReload/ForceReload";
import { useLocation } from 'react-router-dom';

export default function CustomErrorBoundary({ children }) {

  const location =  useLocation();

  const ErrorPageFallback = (error) => {
    if (error?.name === 'ChunkLoadError' || error?.error === 'ChunkLoadError') {
      return <ForceReload/>;
    } else {
      return <ErrorPage />;
    }
  };

  return (
    <ErrorBoundary 
      key={location.pathname} 
      fallbackRender={ErrorPageFallback}
    >
      { children }
    </ErrorBoundary>
  );
}