import { Header } from './components/Header';
import { HeaderSignupBtns } from './components/HeaderSignupBtns';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import { Divider } from 'antd';
import { LanguageDropdown } from '../../Navbar/components/LanguageDropdown/LanguageDropdown';
import LoginSignupModal from 'pages/LoginSignup/components/LoginSignupModal/LoginSignupModal';

import './home-header.scss';
import RedirectionButtons from 'components/Navbar/NavbarUnlogged/components/RedirectionButtons/RedirectionButtons';

const HomeHeader = () => {
  const location = useLocation();
  const isLoginPage = ['/login'].includes(location.pathname);
  const isSignupPage = ['/signup'].includes(location.pathname);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loginSignupModalType, setLoginSignupModalType] = useState(isMobile && isLoginPage ? 'login' : '');
  const [loginSignupModalVisible, setLoginSignupModalVisible] = useState(false);

  const toggleLogin = () => {
    setLoginSignupModalType('login');
    setLoginSignupModalVisible(true);
  };

  const toggleSignup = () => {
    setLoginSignupModalType('signup');
    setLoginSignupModalVisible(true);
  };

  const token = JSON.parse(localStorage.getItem('user'))?.user?.token;

  return (
    <>
      <Header setDropdownOpen={setDropdownOpen} dropdownOpen={dropdownOpen} />
      {isMobile && (
        <div className={classNames('home-header__dropdown', { 'home-header__dropdown--open': dropdownOpen })}>
          <Divider className="mt-0 mb-0" />
          <div className="navbar__btns-btn__has-border">
            <LanguageDropdown isMobile />
          </div>
          {!token && !isSignupPage && !isLoginPage && (
            <>
              <RedirectionButtons isMobile toggleLogin={toggleLogin}/>
              <HeaderSignupBtns
                toggleSignup={toggleSignup}
                setDropdownOpen={setDropdownOpen}
                toggleLogin={toggleLogin}
              />
            </>
          )}
        </div>
      )}
      <LoginSignupModal
        visible={((isSignupPage || isLoginPage) && isMobile) || loginSignupModalVisible}
        isHomeHeader={isSignupPage || isLoginPage}
        closeModal={() => setLoginSignupModalVisible(false)}
        openModal={loginSignupModalType === 'signup' ? toggleLogin : toggleSignup}
        loginSignupModalType={loginSignupModalType}
      />
    </>
  );
};

export default React.memo(HomeHeader);
