import React, { createContext, useEffect, useState } from 'react';
import usersService from 'services/users.service';

export const CreateOpportunityCtx = createContext();

export function CreateOpportunityCtxProvider({ children }) {
  const [opportunity, setOpportunity] = useState({ networks: ['instagram'] });

  useEffect(() => {
    usersService.getUserInfoSettings().then(({ user }) => {
      setOpportunity((opportunity) => ({
        ...opportunity,
        link: user.website,
        website: user.website,
        website_user: user.website_user,
        brand_name: user.first_name,
      }));
    });
  }, []);

  return (
    <CreateOpportunityCtx.Provider value={{ opportunity, setOpportunity }}>{children}</CreateOpportunityCtx.Provider>
  );
}
