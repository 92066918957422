import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';
import useOpenPricing from 'assets/CustomHooks/use-open-pricing';
import useTest from 'assets/CustomHooks/use-test';
import { eventNames, trackEvent } from 'utils/instrumentation';

export default function UpgradeModal({ visible, toggleVisibility, type, amplitudeInfo }) {
  const { t } = useTranslation('common');
  const openPricing = useOpenPricing();

  const user = JSON.parse(localStorage.getItem('user') || '{}')?.user;
  const isFree = user?.plan === 'free' || !user?.plan;
  const [, abTest] = useTest();
  const isVariant = abTest && abTest['12_24_book_demo_upgrade'] === 'variant' && isFree;

  const requestDemo = () => {
    window.open('https://heepsy.pipedrive.com/scheduler/Z6eM31sx/product-demo', '_blank')
    trackEvent(eventNames.DEMO_FORM_OPENED, {
      origin: window.location.href
    });
    toggleVisibility();
  }

  const cancelButtonText = isVariant 
    ? t('landing_brands.demo_button')
    : t('pipelines.card_content.files_modal.cancel');

  const cancelAction = isVariant ? requestDemo : toggleVisibility;

  return (
    <Modal
      className="account-stats-modal -no-padding"
      open={visible}
      onCancel={toggleVisibility}
      title={t('upgrade.modal.title')}
      centered
      okText={t('upgrade.modal.button')}
      okButtonProps={{
        id: 'proposal-create-opp',
        onClick: () => {
          toggleVisibility();
          openPricing(amplitudeInfo)
        },
      }}
      cancelButtonProps={ {
        id: 'proposal-create-opp-cancel',
        onClick: () => {
          cancelAction();
        },
      }}
      cancelText={cancelButtonText}
    >
      {type === 'invite' ? (
        <p>{t('upgrade.modal.invite')}</p>
      ) : (
        <p>{t('upgrade.modal.text')}</p>
      )}
    </Modal>
  );
}
