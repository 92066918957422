import { useCallback, useContext } from 'react';
import { RequestNetworkCtx } from 'components/RequestNetworksModal/context/request-network-context';
import usersService from 'services/users.service';

const useHandleValidator = () => {
  const { dispatch } = useContext(RequestNetworkCtx);

  const verifyHandle = useCallback(
    async (handle, network) => {
      const data = await usersService.checkHandle(handle.toLowerCase(), network);

      if (data.already_exists && network === data.network) {
        dispatch({
          type: 'SET_IS_HANDLE_INVALID',
          payload: { network, value: true },
        });
        return false;
      } else {
        dispatch({
          type: 'SET_IS_HANDLE_INVALID',
          payload: { network, value: false },
        });
        return true;
      }
    },
    [dispatch],
  );

  return { verifyHandle };
};

export default useHandleValidator;
