import interceptor from './interceptor.service';
import { hardRefresh, shouldUpdate } from 'utils/hardRefresh';
import { version } from 'version';
import { updateUserInfo, shouldUpdateUserInfo } from 'utils/userInfo';
import { every, isNil, isObject, omitBy } from 'lodash';
import usersService from './users.service';

const config = { apiUrl: process.env.REACT_APP_API_URL };

function handleResponse(response) {
  if (shouldUpdate(response)) hardRefresh();
  if (shouldUpdateUserInfo(response)) updateUserInfo(response);
  switch (response.status) {
    case 422:
      usersService.logout()
      return { status: 422, data: null };
    case 401:
      return { status: 401, data: null };
    case 403:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 403, data };
      });
    case 404:
      return { status: 404, data: null };
    case 200:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return { status: 200, data };
      });
    default:
      return { status: response.status, data: null };
  }
}

function createOpportunity(opportunity) {
  const validAttributes = [
    'name',
    'description',
    'currency',
    'estimated_value',
    'hashtags',
    'mentions',
    'link',
    'time_limit',
    'enable_fee',
    'commission',
    'enable_delivery',
    'category_id',
    'is_global',
    'expected_deals',
    'minimum_followers',
    'minimum_engagement',
    'networks',
    'brand_name',
    'website_user'
  ];
  const formData = new FormData();

  if ((opportunity.hasOwnProperty('enable_delivery') && !opportunity.enable_delivery) || opportunity.delivery_type === 'locations') {
    //walk in
    delete opportunity.is_global;
  } else {
    // delivery
    delete opportunity.locations;
  }
  delete opportunity.delivery_type;

  if (opportunity.croppedImages) {
    opportunity.croppedImages.map((image) => formData.append('product_images', image.image));
  }

  if (!opportunity.is_global) {
    if (opportunity.locations) {
      formData.append('locations', JSON.stringify(opportunity.locations));
    }
  }

  if((opportunity.catalogue || []).length > 0) {
    formData.append('catalogue', JSON.stringify(opportunity.catalogue));
  }

  if (opportunity.tags) formData.append('tags', opportunity.tags);
  if (!opportunity.minimum_followers) formData.append('minimum_followers', 1500);
  if (!opportunity.minimum_engagement) formData.append('minimum_engagement', 2);

  Object.keys(opportunity).map((key) =>
    validAttributes.includes(key) ? formData.append(key, opportunity[key]) : null,
  );
  opportunity = omitBy(opportunity, isNil);

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: formData,
  };

  return interceptor(`${config.apiUrl}/api/opportunities`, requestOptions)
    .then(handleResponse)
    .then(({ data }) => data);
}

function changeOpportunityVisibility(opportunityParams) {
  const formData = new FormData();
  formData.append('id', opportunityParams.id);
  formData.append('enabled', opportunityParams.enabled);

  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: formData,
  };

  return interceptor(`${config.apiUrl}/api/opportunities`, requestOptions)
    .then(handleResponse)
    .then(({ data }) => {
      const opportunity = data;
      return opportunity;
    });
}

async function changeOpportunity(opportunity) {
  const formData = new FormData();

  if (every(opportunity.tags, isObject)) {
    opportunity.tags = opportunity.tags.map((tag) => tag.id)
  }

  Object.keys(opportunity)
    .filter((k) => k !== 'newImages' && opportunity[k] !== undefined)
    .map((k) => formData.append(k, opportunity[k]));

  opportunity.newImages.map((image) => formData.append('product_images', image.image));

  const requestOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: formData,
  };

  return interceptor(`${config.apiUrl}/api/opportunities`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      return { opportunity: data, status };
    });
}

function getOpportunity(opportunityId) {
  const requestOptions = {
    method: 'GET',
  };

  const token = JSON.parse(localStorage.getItem('user'))?.user?.token;

  requestOptions.headers = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
        Version: version,
      }
    : {
        'Content-Type': 'application/json',
      };

  return interceptor(`${config.apiUrl}/api/opportunities/${opportunityId}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      const opportunity = data;
      return { opportunity, status };
    });
}

function deleteOpportunity(opportunityId) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
  };

  return interceptor(`${config.apiUrl}/api/opportunities/${opportunityId}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      const opportunity = data;
      return { opportunity, status };
    });
}

function boostOpportunity(id) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
    body: JSON.stringify({ id }),
  };

  return interceptor(`${config.apiUrl}/api/opportunities/boost`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      const updatedOpportunity = data;
      return { opportunity: updatedOpportunity, status };
    });
}

function getMyOpportunities() {
  const requestOptions = {
    method: 'GET',
  };

  const token = JSON.parse(localStorage.getItem('user'))?.user?.token;

  requestOptions.headers = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
        Version: version,
      }
    : {
        'Content-Type': 'application/json',
        Version: version,
      };

  return interceptor(`${config.apiUrl}/api/myOpportunities`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      return { data, status };
    });
}

function getOpportunities() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      Version: version,
    },
  };

  return interceptor(`${config.apiUrl}/api/opportunities`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      return { data, status };
    });
}

function getPictureSuggestions(cat) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
  };

  return interceptor(`${config.apiUrl}/api/opportunities_pictures/${encodeURIComponent(cat)}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      return data;
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createOpportunity,
  changeOpportunityVisibility,
  getOpportunity,
  deleteOpportunity,
  boostOpportunity,
  getMyOpportunities,
  changeOpportunity,
  getOpportunities,
  getPictureSuggestions,
};
