import interceptor from './interceptor.service';
import { updateUserInfo, shouldUpdateUserInfo } from 'utils/userInfo';

function handleResponse(response) {
  if (shouldUpdateUserInfo(response)) updateUserInfo(response);
  switch (response.status) {
    case 400:
      return {status: 400, data: 'User does not have a password'};
    case 422:
      return {status: 422, data: null};
    case 401:
      return {status: 401, data: null};
    case 200:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return {status: 200, data};
      });
    default:
      console.error('Unknown status code');
      return null;
  }
}

function requestPasswordReset(email) {
  email = email.toString().toLowerCase();
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({email}),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/forgot_password`, requestOptions)
    .then(handleResponse)
    .then((response) => response);
}

function checkToken(token) {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'},
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/check_token/${token}`, requestOptions)
    .then(handleResponse)
    .then((response) => response);
}

function changePassword(password, confirmPassword, token) {
  const requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({password, password_confirm: confirmPassword, token}),
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/reset_password    `, requestOptions)
    .then(handleResponse)
    .then((response) => response);
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  requestPasswordReset,
  changePassword,
  checkToken,
};
