import { useContext } from 'react';
import { MobileMenuContext } from 'context/mobileMenuContext';

export default function useMobileMenu() {
  const { profileMenuIsOpen, setProfileMenuIsOpen } = useContext(MobileMenuContext);
  const openProfileMenu = () => setProfileMenuIsOpen(true);
  const closeProfileMenu = () => setProfileMenuIsOpen(false);
  const toggleProfileMenu = () => setProfileMenuIsOpen((isOpen) => !isOpen);
  return { profileMenuIsOpen, openProfileMenu, closeProfileMenu, toggleProfileMenu };
}
