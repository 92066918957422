import { useMemo } from 'react';

export default function usePermissions() {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : {};

  return useMemo(() => {
    const product = user?.stripe_product_info;

    return [product];
  }, [user?.stripe_product_info]);
}
