const networkToFees = {
  instagram: [
    { type: 'cost_per_story', value: 'storyPrice' },
    { type: 'cost_per_post', value: 'postPrice' },
    { type: 'cost_per_reels', value: 'reelPrice' },
  ],
  tiktok: [{ type: 'cost_per_post', value: 'postPrice', name: 'cost_per_videos' }],
  youtube: [{ type: 'cost_per_post', value: 'postPrice', name: 'cost_per_videos' }],
};

export default networkToFees;
