import { Button } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { isMobile } from 'react-device-detect';

const SurveyScore = ({ score, setSelectedScore, setStep }) => {
  const mapArrays = (obj) => {
    const keys = Object.keys(obj);

    return keys.map((key, i) => {
      if (Array.isArray(obj[key])) {
        return (
          <div key={key} className="d-flex justify-content-between">
            {obj[key].map((item) => (
              <Button
                type="primary"
                size="small"
                danger={i === 0}
                style={i === 1 ? { backgroundColor: '#FFA500' } : {}}
                className={classNames({ 'px-2 mx-2': !isMobile, 'mx-1': isMobile })}
                onClick={() => {
                  setSelectedScore(item);
                  setStep();
                }}
              >
                {item}
              </Button>
            ))}
          </div>
        );
      }
      return null;
    });
  };

  return <div className="d-flex justify-content-center">{mapArrays(score)}</div>;
};

export default SurveyScore;
