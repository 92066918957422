import isProduction from 'helpers/isProduction';
import { getAbTestCookie, manageTestsCookie } from './abTest';

const getABTestsTraits = () => {
  manageTestsCookie();
  const ab_tests = getAbTestCookie();
  return !!Object.keys(ab_tests).length ? Object.keys(ab_tests).map((k) => `${k}__${ab_tests[k]}`) : [];
};

export const trackEvent = (event, properties = {}) => {
  try {
    window.analytics.track(event, properties);
  } catch (error) {
    console.log(error);
  }
};

export const trackPageView = (properties = {}, options = {}) => {
  try {
    window.analytics.page(properties, options);
  } catch (error) {
    console.log(error);
  }
};

export const identifyUser = (userId, userTraits = {}, options = {}) => {
  try {
    const tests = getABTestsTraits();
    const completeUserTraits = {
      ...userTraits,
      tests,
    };
    const prepend = isProduction() ? 'user' : 'staging';
    window.analytics.identify(`${prepend}_${userId}`, completeUserTraits, options);
  } catch (error) {
    console.log(error);
  }
};

export const getSegmentAnoymousId = () => {
  try {
    return window.analytics.user().anonymousId();
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const eventNames = {
  //User
  USER_STARTED_CHURN: 'User Started Churn',
  USER_FINISHED_CHURN: 'User Finished Churn',
  USER_DOWNLOADED_PROFILE: 'User Downloaded Profile',
  USER_EDITED: 'User Edited',
  // Search filters
  SEARCH_FILTER_CLICKED: 'Search Filter Clicked',
  // Demo
  DEMO_FORM_OPENED: 'Demo Form Opened',
  // PRICING
  PRICING_CLICKED: 'Pricing Clicked',
};
