import React, { createContext, useReducer } from 'react';

export const MarketplaceCtx = createContext();

const initialState = {
  selectedCategories: [],
  selectedNetworks: [],
  selectedCollaborationType: [],
  minFollowers: 3,
  maxFollowers: 100,
  minGiftValue: undefined,
  maxGiftValue: undefined,
  currency: undefined,
  fullCountOfResults: 0,
  infiniteScrollAtBottom: false,
  campaigns: [],
  campaignsLoaded: false,
  lastClickedOpportunity: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SELECTED_CATEGORIES':
      return {
        ...state,
        selectedCategories: action.payload,
      };
    case 'SET_SELECTED_NETWORKS':
      return {
        ...state,
        selectedNetworks: action.payload,
      };
    case 'REMOVE_SELECTED_CATEGORIES':
      return {
        ...state,
        selectedCategories: [],
      };
    case 'REMOVE_SELECTED_NETWORKS':
      return {
        ...state,
        selectedNetworks: [],
      };
    case 'SET_SELECTED_COLLABORATION_TYPE':
      return {
        ...state,
        selectedCollaborationType: action.payload,
      };
    case 'REMOVE_SELECTED_COLLABORATION_TYPE':
      return {
        ...state,
        selectedCollaborationType: [],
      };
    case 'SET_MIN_FOLLOWERS':
      return {
        ...state,
        minFollowers: action.payload,
      };
    case 'SET_MAX_FOLLOWERS':
      return {
        ...state,
        maxFollowers: action.payload,
      };
    case 'SET_MIN_GIFT_VALUE':
      return {
        ...state,
        minGiftValue: action.payload,
      };
    case 'SET_MAX_GIFT_VALUE':
      return {
        ...state,
        maxGiftValue: action.payload,
      };
    case 'SET_CURRENCY':
      return {
        ...state,
        currency: action.payload,
      };
    case 'ADD_SEARCH_RESULTS':
      return {
        ...state,
        campaigns: action.payload.campaigns,
        fullCountOfResults: action.payload.fullCountOfResults,
        infiniteScrollAtBottom: false,
        campaignsLoaded: true,
        lastClickedOpportunity: null,
      };
    case 'SET_LAST_CLICKED_OPPORTUNITY_MOBILE':
      return {
        ...state,
        lastClickedOpportunity: action.payload,
      };
    case 'RESET_STORE':
      return initialState;
    default:
      return state;
  }
}

export function MarketplaceCtxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <MarketplaceCtx.Provider value={value}>{children}</MarketplaceCtx.Provider>;
}
