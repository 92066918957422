const { pickBy } = require('lodash');
const abTests = require('./abTests.json');

const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8); // eslint-disable-line
    return v.toString(16);
  });
}

const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash + char) ^ (hash >>> 3);
    hash |= 0;
  }
  return Math.abs(hash);
};

export const getOrSetUUID = (user) => {
  const uuidDatabase = user?.uuid;
  const uuidCookie = getCookie('uuid');
  const uuid = uuidDatabase || uuidCookie || generateUUID();

  setCookie('uuid', uuid);

  return uuid;
}

const getGroupForTest = (test, uuid) => {
  const testObj = abTests[test];
  const hash = hashString(`${test}_${uuid}`);
  const possibleValues = Object.keys(testObj.values);
  const group = possibleValues[hash % possibleValues.length];
  return group;
}

export const manageTestsCookie = () => {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : {};
  const uuid = getOrSetUUID(user);

  let ab_tests = getAbTestCookie();

  const ongoingTests = Object.keys(pickBy(abTests, (v) => v.active));
  const testsOld = Object.keys(ab_tests).filter((elem) => Object.keys(abTests).includes(elem) && !ongoingTests.includes(elem));
  if (!ongoingTests.length && !testsOld.length) return;

  for (let test of ongoingTests) {
    const startDate = new Date(abTests[test].start_date);

    if (user.created_at && new Date(user.created_at) < startDate) {
      ab_tests[test] = 'control'
    } else {
      ab_tests[test] = getGroupForTest(test, uuid);
    }
  }

  for (let test of testsOld) {
    delete ab_tests[test];
  }

  setCookie('ab_tests', ab_tests);
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return null;
}

export const getAbTestCookie = () => {
  return JSON.parse(getCookie('ab_tests') || '{}');
}

export const setCookie = (name, data, time=365 * 24 * 60 * 60) => {
  let domain;
  if (process.env.REACT_APP_URL.includes('.heepsy.com')) {
    domain = ';domain=.heepsy.com';
  } else {
    domain = '';
  }
  const dataIsObject = typeof data === 'object' && !Array.isArray(data) && data !== null
  const value = dataIsObject ? JSON.stringify(data) : data
  document.cookie = `${name}=${value}${domain};max-age=${time};path=/`;
}
