import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import BrandsIcon from 'components/Icons/MarketplaceIcon';
import classNames from 'classnames';

export function FindButton({ closeProfileMenu }) {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <Link
      to={'/opportunities'}
      onClick={() => {
        closeProfileMenu();
        navigate('/opportunities');
      }}
      id="menu_my-opportuntiies"
    >
      <div className="profile-menu__item bg-white">
        <div className="d-flex justify-content-start w-100 align-items-center">
          <span className={classNames('ml-0 profile-menu__item-img', { '-no-flex': !isMobile })}>
            <BrandsIcon color={'#19202b'} />
          </span>
          {isMobile ? (
            <div className="profile-menu__item p-0 w-100 border-0">{t('navbar_logged.desktop.find_brands')}</div>
          ) : (
            <div>{t('navbar_logged.desktop.find_brands')}</div>
          )}
        </div>
        {isMobile && <div className="icon-chevron" />}
      </div>
    </Link>
  );
}
