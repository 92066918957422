import { message } from 'antd';

import userService from '../services/users.service';

// eslint-disable-next-line import/no-anonymous-default-export
export default (textMessage, isError, fromSettings) => {
  userService.logout();
  if (!fromSettings) window.location.href = '/';
  if (textMessage) {
    isError ? message.error(textMessage) : message.success(textMessage);
  }
};
