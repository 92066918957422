import './ShareContainer.scss';
import { isMobile } from 'react-device-detect';
import HomeHeader from 'components/Home/HomeHeader/HomeHeader';
import classNames from 'classnames';

export default function ShareContainer({ children }) {
  return (
    <div className={classNames('share-container', isMobile && 'share-container--mobile')}>
      {isMobile ? <HomeHeader /> : null}
      <div style={{ marginTop: isMobile ? 48 : 0 }}>{children}</div>
    </div>
  );
}
