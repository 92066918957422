import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import ErrorImg from 'images/blankslates/500.svg';

import './error-page.scss';

export default function ErrorPage({ type = 'default' }) {
  const { t } = useTranslation('common');

  return (
    <div className="error-page">
      <img src={ErrorImg} alt="" />
      <p>
        {t(`error_page.${type}.1`)}
        <br />
        {t(`error_page.${type}.2`)}
      </p>
      <Button
        type="primary"
        onClick={() => {
          window.location.href = type === 'limit' ? '/pricing' : '/';
        }}
      >
        {t(`error_page.${type}.3`)}
      </Button>
    </div>
  );
}
