import React, { createContext, useState } from 'react';

export const MobileMenuContext = createContext();

export function MobileMenuContextProvider({ children }) {
  const [profileMenuIsOpen, setProfileMenuIsOpen] = useState(false);
  const value = { profileMenuIsOpen, setProfileMenuIsOpen };

  return <MobileMenuContext.Provider value={value}>{children}</MobileMenuContext.Provider>;
}
