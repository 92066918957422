import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { Typography, Input } from 'antd';

import { RequestNetworkCtx } from 'components/RequestNetworksModal/context/request-network-context';
import handleValidators from 'utils/handleValidators';
import useHandleValidator from 'components/RequestNetworksModal/hooks/use-handle-validator';

const { Text } = Typography;

const getShorterNetworkName = (network) => {
  if (network === 'instagram') return 'ig';
  if (network === 'tiktok') return 'tt';
  if (network === 'youtube') return 'yt';
};

export function NetworkInput({ network, selectedNetworks }) {
  const { t } = useTranslation('common');

  const { verifyHandle } = useHandleValidator();

  const {
    state: { igHandle, ttHandle, ytHandle, igHandleError, ttHandleError, ytHandleError },
    dispatch,
  } = useContext(RequestNetworkCtx);

  const requiredInput = () => {
    if (network === 'youtube') return !igHandle && !ttHandle && selectedNetworks.includes('youtube');
    if (network === 'instagram') return !ytHandle && !ttHandle && selectedNetworks.includes('instagram');
    if (network === 'tiktok') return !ytHandle && !igHandle && selectedNetworks.includes('tiktok');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateHandle = useCallback(
    debounce(async (value) => {
      const verified = await verifyHandle(value, network);
      dispatch({
        type: `SET_${getShorterNetworkName(network).toUpperCase()}_HANDLE_ERROR`,
        payload: verified ? '' : t('signup_form.handle_error'),
      });
    }, 300),
    [network, t, verifyHandle, dispatch],
  );

  const handleChange = async (e) => {
    const value = e.target.value;

    if (network === 'instagram') {
      dispatch({ type: 'SET_IG_HANDLE', payload: value });
    } else if (network === 'tiktok') {
      dispatch({ type: 'SET_TT_HANDLE', payload: value });
    } else {
      dispatch({ type: 'SET_YT_HANDLE', payload: value });
    }

    dispatch({
      type: `SET_${getShorterNetworkName(network).toUpperCase()}_HANDLE_ERROR`,
      payload: '',
    });

    let hasError = false;
    if (!value && requiredInput()) {
      dispatch({
        type: `SET_${getShorterNetworkName(network).toUpperCase()}_HANDLE_ERROR`,
        payload: t('edit_modal.required_error'),
      });
      hasError = true;
    }

    if (!hasError) {
      const additionalValidator = handleValidators(network, t, dispatch).validator;
      try {
        await additionalValidator({}, value);
      } catch (validationError) {
        dispatch({
          type: `SET_${getShorterNetworkName(network).toUpperCase()}_HANDLE_ERROR`,
          payload: validationError.message,
        });
        hasError = true;
      }
    }

    if (!hasError) {
      validateHandle(value);
    }
  };

  useEffect(() => {
    return () => {
      validateHandle.cancel();
    };
  }, [validateHandle]);

  const error = network === 'instagram' ? igHandleError : network === 'tiktok' ? ttHandleError : ytHandleError;

  const shouldHideError = error === t('edit_modal.required_error') && !requiredInput();

  return (
    <div className="position-relative mb-4">
      <p className="request-networks-modal__label">
        {requiredInput() && (
          <Text type="danger">
            <sup>*</sup>
          </Text>
        )}
        {t(`signup_form.form_${network}`)}
      </p>
      <Input
        status={error && !shouldHideError ? 'error' : ''}
        value={network === 'instagram' ? igHandle : network === 'tiktok' ? ttHandle : ytHandle}
        prefix={'@'}
        placeholder={t(`signup_form.placeholder_${network}`)}
        onChange={handleChange}
      />
      {error && !shouldHideError && <Text type="danger">{error}</Text>}
    </div>
  );
}
