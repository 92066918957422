import React from 'react';
import PropTypes from 'prop-types';

const ConditionalWrap = ({condition, wrap, mobileWrap, children}) =>
  condition ? wrap(children) : mobileWrap(children);

ConditionalWrap.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrap: PropTypes.func.isRequired,
  mobileWrap: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ConditionalWrap.defaultProps = {
  mobileWrap: (children) => <>{children}</>,
};

export default ConditionalWrap;
