import { InstagramFilled, YoutubeFilled } from '@ant-design/icons';
import classNames from 'classnames';
import TiktokIcon3 from 'components/Icons/TiktokIcon3';

// eslint-disable-next-line import/no-anonymous-default-export
export default (network, noMargin, noMarginForIcon) => {
  if (network === 'instagram' || network === 'ig')
    return (
      <div className={classNames('d-flex align-items-center', { 'ml-0': noMargin, 'mx-2': !noMarginForIcon })}>
        <InstagramFilled style={{ color: '#000' }} />
      </div>
    );
  else if (network === 'tiktok' || network === 'tt')
    return (
      <div className={classNames('d-flex align-items-center', { 'ml-0': noMargin, 'mx-2': !noMarginForIcon })}>
        <TiktokIcon3 />
      </div>
    );
  else
    return (
      <div className={classNames('d-flex align-items-center', { 'ml-0': noMargin, 'mx-2': !noMarginForIcon })}>
        <YoutubeFilled />
      </div>
    );
};
