import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import config from 'assets/config/queryParamsConfig';
import { mapValues } from 'lodash';
import { useCallback, useMemo } from 'react';

const { parse, stringify } = queryString;

const queryStringParsingSettings = {
  arrayFormat: 'bracket',
};

export const queryStringParser = (queryString) => parse(queryString.toString(), queryStringParsingSettings);
export const queryStringStringifier = (query) => stringify(query, queryStringParsingSettings);

const parseSearchParams = (searchParams, config) => {
  const parsedQuery = queryStringParser(searchParams.toString());

  const formattedQuery = Object.entries(parsedQuery).map(([key, value]) => {
    const type = config[key]?.type ?? 'string';
    const formater = type === 'number' ? (value) => parseFloat(value) : (value) => value;
    const formattedValue = Array.isArray(value) ? value.map(formater) : formater(value);
    return [key, formattedValue];
  });

  return Object.fromEntries(formattedQuery);
};

export default function useUrlQuery() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useMemo(() => parseSearchParams(searchParams, config), [searchParams]);

  const updateQuery = useCallback(
    (query_) => {
      const queryWithFormattedFields = mapValues(query_, (value, key) => {
        if (config[key]?.isArray && value !== undefined) {
          return [value].flat();
        }
        return value;
      });
      const oldQuery = queryStringParser(window.location.search);
      const newQuery = { ...oldQuery, ...queryWithFormattedFields };

      setSearchParams(queryStringStringifier(newQuery));
    },
    [setSearchParams],
  );

  const getQuery = useCallback(
    (query_) => {
      const queryWithFormattedFields = mapValues(query_, (value, key) => {
        if (config[key]?.isArray && value !== undefined) {
          return [value].flat();
        }
        return value;
      });
      const oldQuery = queryStringParser(window.location.search);
      const newQuery = { ...oldQuery, ...queryWithFormattedFields };

      return queryStringStringifier(newQuery)
    },
    [],
  );

  const clearQuery = (keys) => {
    setSearchParams((oldSearchParams) => {
      const queryToClear = Object.fromEntries(keys.map((key) => [key, undefined]));
      const oldQuery = queryStringParser(oldSearchParams.toString());
      const newQuery = { ...oldQuery, ...queryToClear };
      return queryStringStringifier(newQuery);
    });
  };

  return { query, updateQuery, getQuery, clearQuery, setSearchParams };
}
