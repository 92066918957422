import React, { useState } from 'react';
import { message, Modal, Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import passwordService from 'services/password.service';

const ForgotPassword = ({ toggleVisibility, visible }) => {
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const onFinish = (values) => {
    const { email } = values;
    setIsLoading(true);
    setErrorMsg(null);

    passwordService.requestPasswordReset(email).then((response) => {
      if (response?.data === 'User does not have a password') {
        setIsLoading(false);
        setErrorMsg(t('forgot_password.no_pass'));
      } else if (!response?.data) {
        setIsLoading(false);
        setErrorMsg(t('forgot_password.no_user'));
      } else {
        message.success(t('forgot_password.success'));
        toggleVisibility();
        setIsLoading(false);
      }
    });
  };

  return (
    <Modal
      title={t('forgot_password.title')}
      footer={null}
      onOk={toggleVisibility}
      onCancel={toggleVisibility}
      open={visible}
      centered
      destroyOnClose
    >
      <Form layout="vertical" name="basic" onFinish={onFinish} autoComplete="off">
        <p>{t('forgot_password.info')}</p>

        <div className="position-relative">
          <Form.Item
            label="Email"
            name="email"
            className={classNames({ '-has-error': errorMsg })}
            rules={[
              {
                required: true,
                message: t('forgot_password.required_error'),
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          {errorMsg && (
            <span className="form-error-message" style={{ color: '#ff4d4f' }}>
              {errorMsg}
            </span>
          )}
        </div>

        <div className="d-flex justify-content-end modal-footer mt-4">
          <Button type="default" className="mr-2" onClick={toggleVisibility}>
            {t('pipelines.card_content.files_modal.cancel')}
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('forgot_password.button')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default React.memo(ForgotPassword);
