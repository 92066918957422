import React, { useContext, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';

import { SurveyCtx } from './contexts/survey-ctx';
import surveysService from 'services/surveys.service';
import SurveyScore from './components/SurveyScore';
import SurveyFeedback from './components/SurveyFeedback';

import './survey.scss';

const Survey = () => {
  const { t } = useTranslation('common');
  const { state, dispatch } = useContext(SurveyCtx);
  const [step, setStep] = useState('survey');
  const [selectedScore, setSelectedScore] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [hideSurvey, setHideSurvey] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'))?.user;

  useEffect(() => {
    const fetchSurvey = async () => {
      dispatch({ type: 'SURVEY_SHOWN', payload: true });
      const data = await surveysService.showSurvey();
      if (data.survey) {
        dispatch({ type: 'SURVEY', payload: data.survey });
      }
    };
  
    if (!state.survey_shown && user?.token) {
      fetchSurvey();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitData = async () => {
    try {
      setHideSurvey(true);
      const resp = await surveysService.createSurvey({
        survey: state.survey.survey,
        comment: feedback,
        rating: selectedScore,
      });

      if (resp.success) {
        selectedScore && message.success(t('survey.success'));
      } else {
        message.error(t('survey.error'));
      }
    } catch (error) {
      message.error(t('survey.error'));
    }
  };

  return state.survey && !hideSurvey ? (
    <div className="survey-container">
      <div className="d-flex justify-content-end">
        <Button type="text" onClick={submitData} icon={<CloseOutlined />} shape="circle" />
      </div>
      {step === 'survey' ? (
        <>
          <p>{t(`survey.${state.survey.survey}.question`)}</p>

          <SurveyScore
            score={state.survey.data}
            setStep={() => setStep('feedback')}
            setSelectedScore={setSelectedScore}
          />
        </>
      ) : (
        <SurveyFeedback
          selectedScore={selectedScore}
          survey={state.survey}
          setFeedback={setFeedback}
          feedback={feedback}
        />
      )}
      {selectedScore !== null && (
        <div className="d-flex justify-content-end mt-4">
          <Button type="primary" onClick={submitData}>
            {t('survey.submit')}
          </Button>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Survey;
