import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import useAfterAuthRedirections from 'assets/CustomHooks/use-after-auth-redirections';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import { pickBy } from 'lodash';
import defaultRedirectUrl from 'helpers/defaultRedirectUrl';

import logout from 'helpers/logout';

const getUtms = (location) => {
  let utmParams = pickBy(queryString.parse(location.search), (value, key) => {
    return key === 'utm_source' || key === 'utm_campaign' || key === 'utm_medium';
  });
  return queryString.stringify(utmParams, {
    arrayFormat: 'repeat',
  });
};

const PrivateRoute = ({ children, allowedUserTypes = 'all' }) => {
  const location = useLocation();
  const { setAfterAuthRedirectionLocation } = useAfterAuthRedirections();
  let isUserLoggedIn = false;
  let userType;

  const userInfo = localStorage.getItem('user');
  if (userInfo) {
    const {
      user: { token, account_type },
    } = JSON.parse(userInfo);
    const { exp } = jwt_decode(token);
    if (!(Date.now() >= exp * 1000)) {
      isUserLoggedIn = true;
      userType = account_type;
    } else {
      const textMessage = 'Session expired, please, login again';
      const isError = true;
      logout(textMessage, isError);
    }
  }

  if (!isUserLoggedIn) {
    setAfterAuthRedirectionLocation();
  }

  const loggedInUserTypeIsAllowed = allowedUserTypes === 'all' || allowedUserTypes.includes(userType);

  if (!isUserLoggedIn) return <Navigate to={`/login?${getUtms(location)}`} />;

  if (!loggedInUserTypeIsAllowed) return <Navigate to={defaultRedirectUrl(userType)} />;

  return children;
};

export default PrivateRoute;
