/* eslint-disable no-undef */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Divider } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

import ConditionalWrap from 'utils/ConditionalWrap';
import settingsIcon from 'images/illustrations/settings-icon.svg';
import helpIcon from 'images/illustrations/help-icon.svg';
import inviteIcon from 'images/illustrations/invite-icon.svg';
import walletIcon from 'images/illustrations/wallet-icon.svg';
import { showIntercom } from 'assets/helpers/helpers';

export function BrandLink({ closeProfileMenu, link }) {
  const navigate = useNavigate();

  const { t } = useTranslation('common');
  const onClickMobile = () => {
    switch (link.id) {
      case 2:
      case 3:
      case 8:
      case 10:
        navigate(link.link);
        break;
      case 6:
        window.open('https://intercom.help/heepsy/en', '_blank');
        break;
      case 7:
        showIntercom();
        break;
      default:
        break;
    }
    if (link.id !== 7) closeProfileMenu();
  };

  return (
    <>
      {link.id === 3 && isMobile && (
        <Divider
          className="mb-2 mt-2"
          style={{
            background: '#E8EBEC',
          }}
        />
      )}
      {!link.hideOnDesktop && (
        <ConditionalWrap
          key={link}
          condition={!isMobile}
          wrap={(children) => (
            <Link to={link.link} onClick={closeProfileMenu}>
              {children}
            </Link>
          )}
          mobileWrap={(children) => (
            <div role="button" className="profile-menu__button" onClick={onClickMobile}>
              {children}
            </div>
          )}
        >
          <div className="profile-menu__item">
            <div className='d-flex'>
              {isMobile &&
                (link.text === t('navbar_logged.dropdown.help') ? (
                  <QuestionCircleOutlined
                    className="mr-3 ml-0"
                    style={{
                      fontSize: 20,
                    }}
                  />
                ) : (
                  <div className="profile-menu__item-img">
                    <img
                      className="mr-3"
                      src={
                        link.text === t('navbar_logged.dropdown.settings')
                          ? settingsIcon
                          : link.id === 7
                          ? helpIcon
                          : link.id === 8
                          ? walletIcon
                          : inviteIcon
                      }
                      alt=""
                    />
                  </div>
                ))}
              {link.text}
            </div>
            {isMobile && link.id !== 7 && <div className="icon-chevron" />}
          </div>
        </ConditionalWrap>
      )}
    </>
  );
}
