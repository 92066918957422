import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

export default function SurveyFeedback({ selectedScore, survey, setFeedback, feedback }) {
  const { t } = useTranslation('common');
  const [response, setResponse] = useState('default');

  useEffect(() => {
    if (selectedScore !== null) {
      Object.keys(survey.data).forEach((key) => {
        if (survey.data[key].includes(selectedScore)) {
          setResponse(key);
        }
      });
    }
  }, [selectedScore]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('text/plain');
    const maxLength = 1200;
    const remainingChars = maxLength - feedback.length;

    if (pastedText.length > remainingChars) {
      e.preventDefault();
      const truncatedText = pastedText.slice(0, remainingChars);
      const newValue = feedback + truncatedText;
      setFeedback(newValue);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 1200) {
      setFeedback(newValue);
    }
  };

  return (
    <div>
      <p>{t(`survey.${survey.survey}.${response}`)}</p>
      <TextArea
        rows={4}
        maxLength={1200}
        showCount
        placeholder="Optional"
        onPaste={handlePaste}
        onChange={handleChange}
        value={feedback}
      />
    </div>
  );
}
