const handleValidators = (network, t, dispatch) => {
  if (network === 'instagram') {
    return {
      validator(_, value) {
        if (!value) return Promise.resolve();
        const format = /[ `!#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/; // eslint-disable-line
        const newVal = value.startsWith('@') ? value.replace('@', '') : value;

        if (
          (!newVal && value) ||
          (newVal &&
            (newVal.includes('@') ||
              newVal.endsWith('.') ||
              newVal.startsWith('.') ||
              newVal.length < 3 ||
              newVal.length > 31 ||
              /^\d*$/.test(newVal) ||
              /\.{2,}/.test(newVal) ||
              format.test(newVal)))
        ) {
          dispatch({ type: 'SET_IG_HANDLE_HAS_ERROR', payload: true });
          return Promise.reject(new Error(t('signup_form.handle_match_error')));
        }
        dispatch({ type: 'SET_IG_HANDLE_HAS_ERROR', payload: false });
        return Promise.resolve();
      },
    };
  }
  if (network === 'tiktok') {
    return {
      validator(_, value) {
        if (!value) return Promise.resolve();
        const format = /[ `!#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/; // eslint-disable-line
        const newVal = value.startsWith('@') ? value.replace('@', '') : value;

        if (
          (!newVal && value) ||
          (newVal &&
            (newVal.includes('@') ||
              newVal.endsWith('.') ||
              newVal.length < 2 ||
              newVal.length > 24 ||
              /^\d*$/.test(newVal) ||
              format.test(newVal)))
        ) {
          dispatch({ type: 'SET_TT_HANDLE_HAS_ERROR', payload: true });
          return Promise.reject(new Error(t('signup_form.handle_match_error')));
        }
        dispatch({ type: 'SET_TT_HANDLE_HAS_ERROR', payload: false });
        return Promise.resolve();
      },
    };
  }
  if (network === 'youtube') {
    return {
      validator(_, value) {
        if (!value) return Promise.resolve();
        const format = /[ `!#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/; // eslint-disable-line
        const newVal = value.startsWith('@') ? value.replace('@', '') : value;

        if (
          (!newVal && value) ||
          (newVal &&
            (newVal.includes('@') ||
              newVal.length < 3 ||
              newVal.length > 30 ||
              /^\d*$/.test(newVal) ||
              format.test(newVal)))
        ) {
          dispatch({ type: 'SET_YT_HANDLE_HAS_ERROR', payload: true });
          return Promise.reject(new Error(t('signup_form.handle_match_error')));
        }
        dispatch({ type: 'SET_YT_HANDLE_HAS_ERROR', payload: false });
        return Promise.resolve();
      },
    };
  }
};

export default handleValidators;
