import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { isMobile } from 'react-device-detect';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import classNames from 'classnames';

import availableNetworks from 'utils/availableNetworks';
import renderNetworkIcon from 'helpers/renderNetworkIcon';
import { RequestNetworkCtx } from 'components/RequestNetworksModal/context/request-network-context';

import '../../../../pages/CreateOpportunityPage/create-opportunity-page.scss';

const { Text } = Typography;

export default function NetworkBtns() {
  const { t } = useTranslation('common');

  const {
    state: {
      igHandle,
      ttHandle,
      ytHandle,
      isHandleInvalid,
      ttHandleHasError,
      ytHandleHasError,
      igHandleHasError,
      selectedNetworks,
    },
    dispatch,
  } = useContext(RequestNetworkCtx || {});

  const prettifyNetwork = (network) => {
    if (network === 'tiktok') return 'TikTok';
    if (network === 'youtube') return 'YouTube';
    return capitalize(network);
  };

  return (
    <div className="mb-2">
      <p className="request-networks-modal__label">
        <Text type="danger">
          <sup>*</sup>
        </Text>
        {t('new_opportunity.requirements.networks')}
      </p>
      <div className="d-flex justify-content-between">
        {availableNetworks.map((network) => (
          <div className="opportunity-page__network mb-0" key={network}>
            <Button
              className={classNames('opportunity-page__network-btn w-100 justify-content-center', {
                '-is-active': selectedNetworks?.includes(network),
                '-is-invalid':
                  (network === 'instagram' && igHandle && (igHandleHasError || isHandleInvalid.instagram)) ||
                  (network === 'tiktok' && ttHandle && (ttHandleHasError || isHandleInvalid.tiktok)) ||
                  (network === 'youtube' && ytHandle && (ytHandleHasError || isHandleInvalid.youtube)),
              })}
              onClick={() => dispatch({ type: 'SET_SELECTED_NETWORKS', payload: [network] })}
              type="default"
              icon={renderNetworkIcon(network, true, true)}
            >
              {!isMobile && prettifyNetwork(network)}{' '}
              {((network === 'instagram' && igHandle && !igHandleHasError && !isHandleInvalid.instagram) ||
                (network === 'tiktok' && ttHandle && !ttHandleHasError && !isHandleInvalid.tiktok) ||
                (network === 'youtube' && ytHandle && !ytHandleHasError && !isHandleInvalid.youtube)) && (
                <span>
                  &nbsp;
                  <CheckOutlined style={{ color: '#00a742' }} />
                </span>
              )}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
