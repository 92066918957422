import interceptor from './interceptor.service';
import { updateUserInfo, shouldUpdateUserInfo } from 'utils/userInfo';

function handleResponse(response) {
  if (shouldUpdateUserInfo(response)) updateUserInfo(response);
  if (response.status === 200) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      return {status: 200, data};
    });
  }
  switch (response.status) {
    case 400:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return {status: 400, data};
      });
    case 403:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return {status: 403, data};
      });
    case 404:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return {status: 404, data};
      });
    case 500:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return {status: 500, data};
      });
    case 409:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return {status: 409, data};
      });
    default:
      console.error('Unknown status code');
      return {status: response.status, data: null};
  }
}

async function getInvoices() {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      'Content-Type': 'application/json'
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/stripe/get_invoices`, requestOptions)
    .then(handleResponse)
    .then(({status, data}) => {
      if (data && status === 200) {
        return data.invoices
      }
      const error = {status, data};
      return Promise.reject(error);
    });
};

async function getPortal() {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      'Content-Type': 'application/json'
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/stripe/get_portal`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) {
        return data.portalUrl
      }
      const error = {status, data};
      return Promise.reject(error);
    });
};

async function createSingleCheckout(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data })
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/stripe/single_checkout`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) {
        return data.checkoutUrl
      }
      const error = {status, data};
      return Promise.reject(error);
    });
};

async function createSubscriptionCheckout(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data })
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/stripe/subscription_checkout`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) {
        return data.checkoutUrl
      }
      const error = {status, data};
      return Promise.reject(error);
    });
};

async function getPlans() {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization:`Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      'Content-Type': 'application/json'
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/stripe/get_plans`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) {
        return data
      }
      const error = {status, data};
      return Promise.reject(error);
    });
};

async function getPlan() {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      'Content-Type': 'application/json'
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/stripe/get_plan`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) {
        return data.plan
      }
      const error = {status, data};
      return Promise.reject(error);
    });
};

async function retrievePlan(id) {
  const requestOptions = {
    method: 'GET',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      'Content-Type': 'application/json'
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/stripe/get_plan/${id}`, requestOptions)
    .then(handleResponse)
    .then(({ status, data }) => {
      if (data && status === 200) {
        return data.plan
      }
      const error = {status, data};
      return Promise.reject(error);
    });
};

async function requestChurn(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data })
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/stripe/churn`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response
    });
};

async function renewSubscription() {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
      'Content-Type': 'application/json'
    },
  };

  return interceptor(`${process.env.REACT_APP_API_URL}/api/stripe/renew_subscription`, requestOptions)
    .then(handleResponse)
    .then(({ status }) => {
      return status
    });
};

const exportedObject = {
  handleResponse,
  getInvoices,
  getPortal,
  createSingleCheckout,
  createSubscriptionCheckout,
  getPlans,
  getPlan,
  retrievePlan,
  requestChurn,
  renewSubscription,
};

export default exportedObject;