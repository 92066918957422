import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import opportunityService from 'services/opportunity.service';
import './publish-campaign-banner.scss';
import classNames from 'classnames';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import usePermissions from 'assets/CustomHooks/use-permissions';
import UpgradeModal from 'pages/UpgradeModal/UpgradeModal';

export default function PublishCampaignBanner({ setHasBanner }) {
  const { t } = useTranslation('common');
  const location = useLocation();

  const [opportunities, setOpportunities] = useState([]);
  const [showBanner, setShowBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasAnyOpportunity, setHasAnyOpportunity] = useState(false);
  const [showActiveStatus, setShowActiveStatus] = useState(false);
  const [isUpgradeVisible, setUpgradeVisible] = useState(false);

  const [product] = usePermissions();
  const isCampaignCreationAvailable = product?.features?.campaigns;

  let navigate = useNavigate();

  const toggleUpgrade = () => {
    setUpgradeVisible(!isUpgradeVisible);
  };

  useEffect(() => {
    const getOpportunities = async () => {
      const token = JSON.parse(localStorage.getItem('user'))?.user?.token;
      if (token) {
        let opportunitiesTmp = await opportunityService.getOpportunities();
        if (opportunitiesTmp?.data?.length > 0) setHasAnyOpportunity(true);
        setOpportunities(opportunitiesTmp.data);
      }
      setIsLoading(false);
    };

    getOpportunities();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps
  const rootElem = document.getElementById('root');

  useEffect(() => {
    if (!isLoading) {
      if (opportunities?.length === 0) {
        setShowBanner(true);
        setHasAnyOpportunity(false);
      } else if (!opportunities?.some((opp) => opp.enabled)) {
        setShowActiveStatus(true);
        setShowBanner(true);
      } else {
        setShowBanner(false);
      }
    }
  }, [opportunities, isLoading]); // eslint-disable-line

  useEffect(() => {
    if (showBanner && !['/create_opportunity'].includes(location.pathname)) {
      rootElem.classList.add('-has-published-banner');
      if (isDesktop) rootElem.classList.add('-has-published-banner__desktop');
      setHasBanner(true);
    } else {
      rootElem.classList.remove('-has-published-banner');
      setHasBanner(false);
    }
  }, [showBanner]); // eslint-disable-line

  const renderBannerText = () => (
    <p className="publish-campaign-banner__text">
      {!hasAnyOpportunity && (
        <>
          <span
            onClick={() => {
              isCampaignCreationAvailable
                ? navigate('/create_opportunity?step=dealType')
                : toggleUpgrade();
            }}
          >
            {t('publish_campaign_banner.freemium_text_no_collab1')}
          </span>
          &nbsp;{t('publish_campaign_banner.freemium_text_no_collab2')}&nbsp;
          <span
            onClick={() => {
              navigate('/creators?network=instagram');
            }}
          >
            {t('publish_campaign_banner.freemium_text_no_collab3')}
          </span>
          &nbsp;{t('publish_campaign_banner.freemium_text_no_collab4')}
        </>
      )}
      
      {showActiveStatus && t('publish_campaign_banner.paid_text')}
      &nbsp;
    </p>
  );
  return (
    !isLoading &&
    showBanner && (
      <div className='publish-campaign-banner'>
        <Alert
          message={renderBannerText()}
          banner={true}
          showIcon={false}
          className={classNames('publish-campaign-banner__alert', {'publish-campaign-banner__alert-desktop': !isMobileOnly})}
          style={{ position: 'fixed', width: '100%', zIndex: '100', top: 0 }}
        />
        {isUpgradeVisible && <UpgradeModal visible={isUpgradeVisible} toggleVisibility={toggleUpgrade} />}
      </div>
    )
  );
}
