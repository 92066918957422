import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import userService from 'services/users.service';
import useGeoLocation from 'react-ipgeolocation';

import './SignupForm.scss';
import codeToCountry from 'assets/config/codeToCountry';
import pushEventToGtmDataLayer from 'helpers/pushEventToGtmDataLayer';

import { getSegmentAnoymousId } from 'utils/instrumentation';
import { getOrSetUUID } from 'utils/abTest';

export function SignupForm({ openModal, isSignupPage, isModal, accountType }) {
  const { t } = useTranslation('common');
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [form] = Form.useForm();
  const IpLocation = useGeoLocation();

  const handleSubmission = async (values) => {
    setEmailError(false);
    setIsLoading(true);

    const data = {
      email: values.email,
      type: 'manual',
      password: values.password,
      account_type: accountType,
      url: location.pathname + location.search,
      country: codeToCountry[IpLocation?.country],
      lang: localStorage.getItem('lang'),
      uuid: getOrSetUUID(),
    }

    const segmentCookie = getSegmentAnoymousId();
    if (segmentCookie) {
      data.segmentCookie = segmentCookie;
    }

    const response = await userService.signup(data);

    if (response.status === 200) {
      if (accountType === 'brand') {
        pushEventToGtmDataLayer(
          'brands_agencies_sign_up',
          {
            user_email: response?.data?.email,
            user_id: response?.data?.id
          }
        );
      }
      return navigate('/verification', { state: data });
    } else if (response.status === 409) {
      setEmailError(true);
    } else {
      message.error(t('lists.error'))
    }

    setIsLoading(false);
  };

  return (
    <>
      <Form
        data-testid="signup-form"
        id="moonio_signup_form"
        onFinish={handleSubmission}
        layout="vertical"
        name="basic"
        form={form}
      >
        <div className="position-relative">
          <Form.Item
            name={'email'}
            label={t('signup_page.form_email')}
            onChange={() => setEmailError(false)}
            rules={[
              {
                type: 'email',
                required: true,
                message: t('forgot_password.required_error'),
              },
            ]}
          >
            <Input placeholder={t('signup_page.form_email')} data-testid="signup-email" />
          </Form.Item>
          {emailError && (
            <span style={{ color: '#ff4d4f', position: 'absolute', top: 70 }}>{t('signup_page.error_email')}</span>
          )}
        </div>

        <Form.Item
          name="password"
          label={t('signup_page.form_pass')}
          rules={[
            {
              required: true,
              message: t('edit_password.required_error'),
            },
            () => ({
              validator(_, value) {
                if (!value || value.length > 5) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('edit_password.condition')));
              },
            }),
          ]}
        >
          <Input.Password placeholder={t('signup_page.form_pass')} data-testid="signup-password" />
        </Form.Item>

        <Form.Item className="mt-4">
          <Button type="primary" htmlType="submit" block loading={isLoading}>
            {t('login_page.buttons.signup')}
          </Button>
        </Form.Item>

        <div className="d-flex align-items-center justify-content-center">
          <span className="signup__small">{t('signup_page.info')}</span>
          <Button
            onClick={() => {
              if (isModal) {
                openModal();
              } else {
                if (isSignupPage) {
                  navigate('/login');
                }
              }
            }}
            type="text"
            className="w-auto p-0 mb-0 login-signup__btn"
            data-testid="signup-submit"
          >
            <span className="signup__link">{t('signup_page.buttons.login')}</span>
          </Button>
        </div>

        <div className="signup__legal">
          {t('signup_page.legal.info')}{' '}
          <a href={`https://www.heepsy.com/terms-of-service`} target="_blank" rel="noreferrer">
            {t('signup_page.legal.terms')}
          </a>
          ,{' '}
          <a href={`https://www.heepsy.com/privacy-policy`} target="_blank" rel="noreferrer">
            {t('signup_page.legal.policy')}
          </a>{' '}
          {t('signup_page.legal.operator')}{' '}
          <a href={`https://www.heepsy.com/cookies-policy`} target="_blank" rel="noreferrer">
            {t('signup_page.legal.cookies')}
          </a>
        </div>
      </Form>
    </>
  );
}
