/* eslint-disable no-undef */
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

const RedirectionButtons = ({ isMobile, toggleLogin }) => {
  const { t } = useTranslation('common');

  return (
    <div className={classNames('d-flex', { 'flex-direction-column': isMobile, 'ml-6': !isMobile })}>
      {isMobile ? (
        <div className={classNames('', { 'navbar__btns-btn': !isMobile })} onClick={() => toggleLogin()}>
          <div className="profile-menu__item border-0">{t('home_headers.login')}</div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(RedirectionButtons);
