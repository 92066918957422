import { version } from '../version';

const shouldUpdate = (response) => {
  return (
    response.headers.get('version') &&
    parseInt(response.headers.get('version')) > parseInt(version || 0) && 
    ['POST', 'PUT', 'DELETE'].includes(response.headers.get('method'))
  )
};

const hardRefresh = async(servicesWorkers=[], url=null) => {
  servicesWorkers.forEach((r) => r.unregister());
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });
  setTimeout(() => {
    if (!url) window.location.reload();
    else window.location.href = url;
  }, 1000);
};

export { hardRefresh, shouldUpdate };