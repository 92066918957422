import React, { createContext, useReducer } from 'react';

export const SurveyCtx = createContext();

const initialState = {
  survey: null,
  survey_shown: false
};

function reducer(state, action) {
  switch (action.type) {
    case 'SURVEY':
      return {
        ...state,
        survey: action.payload,
      };
    case 'SURVEY_SHOWN':
      return {
        ...state,
        survey_shown: action.payload,
      };
    case 'RESET_STORE':
      return initialState;
    default:
      return state;
  }
}

export function SurveyCtxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <SurveyCtx.Provider value={value}>{children}</SurveyCtx.Provider>;
}
