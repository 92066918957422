import Loader from 'components/Loader/Loader';

export default function ForceReload() {
  const FIVE_MINUTES = 300000;

  const wasForcedReloadedLessThanFiveMinutesAgo = () => {
    try {
      let lastForceReloadAt = localStorage.getItem('lastForceReloadAt');
      let elapsedTime = Date.now() - parseInt(lastForceReloadAt);
      return !lastForceReloadAt || elapsedTime > FIVE_MINUTES ? false : true;
    } catch (error) {
      return false;
    }
  };

  const forceReload = () => {
    if (!wasForcedReloadedLessThanFiveMinutesAgo()) {
      localStorage.setItem('lastForceReloadAt', Date.now());
      window.location.reload();
    }
  };

  forceReload();

  return <Loader />;
}
