import { v4 as generateUuid } from 'uuid';

const AMPLITUDE_DEVICE_ID_COOKIE_NAME = 'amplitudeDeviceId';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  else return undefined;
};

const getAmplitudeDeviceIdFromCookie = () => getCookie(AMPLITUDE_DEVICE_ID_COOKIE_NAME);

export const getAmplitudeDeviceId = () => {
  const { hostname } = window.location;
  const domain = hostname === 'localhost' ? 'localhost' : '.heepsy.com';
  const amplitudeDeviceIdLocalStorage = localStorage.getItem(AMPLITUDE_DEVICE_ID_COOKIE_NAME);
  const amplitudeDeviceId = amplitudeDeviceIdLocalStorage || getAmplitudeDeviceIdFromCookie() || generateUuid();
  const oneYearInSeconds = 365 * 24 * 60 * 60;
  localStorage.setItem(AMPLITUDE_DEVICE_ID_COOKIE_NAME, amplitudeDeviceId);
  document.cookie = `${AMPLITUDE_DEVICE_ID_COOKIE_NAME}=${amplitudeDeviceId};domain=${domain};max-age=${oneYearInSeconds};path=/`;
  return amplitudeDeviceId;
};
