import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Typography } from 'antd';

import { CreatorInputs } from './CreatorInputs';
import { NetworkInput } from './NetworkInput';
import availableNetworks from 'utils/availableNetworks';
import { RequestNetworkCtx } from 'components/RequestNetworksModal/context/request-network-context';
import NetworkBtns from 'components/RequestNetworksModal/components/NetworkBtns/NetworkBtns';

const { Text } = Typography;

export function UserDetails() {
  const { t } = useTranslation('common');

  const {
    dispatch,
    state: { selectedNetworks },
  } = useContext(RequestNetworkCtx);

  const [error, setError] = useState('');

  const renderCreatorNetworkInputs = () => {
    return availableNetworks.map(
      (network) =>
        selectedNetworks.includes(network) && (
          <React.Fragment key={network}>
            <NetworkInput network={network} selectedNetworks={selectedNetworks} />
            <CreatorInputs network={network} />
          </React.Fragment>
        ),
    );
  };

  const validateName = (value) => {
    if (!value.trim()) {
      return t('edit_modal.required_error');
    }
    if (/^\s+$/.test(value)) {
      return t('signup_form.name_match_error');
    }
    return '';
  };

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch({ type: 'SET_NAME', payload: value });
    setError(validateName(value));
  };

  return (
    <div className="user-details">
      <div className="user-details__name mb-5">
        <p className="request-networks-modal__label">
          <Text type="danger">
            <sup>*</sup>
          </Text>
          {t('signup_form.placeholder_1')}
        </p>
        <Input
          status={error ? 'error' : ''}
          placeholder={t(`signup_form.form_name_influencer`)}
          onChange={handleChange}
          showCount
          maxLength={30}
        />
        {error && <Text type="danger">{error}</Text>}
      </div>

      <NetworkBtns />
      {renderCreatorNetworkInputs()}
    </div>
  );
}
