import HomeHeader from '../../Home/HomeHeader/HomeHeader'
import CustomErrorBoundary from 'utils/ErrorBoundary';

const AppLayout = ({children}) => (
  <CustomErrorBoundary>
    <HomeHeader />
    {children}
  </CustomErrorBoundary>
);

export default AppLayout;
