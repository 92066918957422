import React, { createContext, useReducer } from 'react';

export const ChatCtx = createContext();

const initialState = {
  chatClient: null,
  selectedChannelId: null,
  selectedChannelIdDrawer: null,
  selectedCollaborator: null,
  isDrawerVisible: false,
  archivedTab: false,
  isMobileListVisible: true,
  counterpartsHandles: {},
  hasManyMessages: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_CHAT_CLIENT':
      return {
        ...state,
        chatClient: action.payload,
      };
    case 'SET_ARCHIVED_TAB':
      return {
        ...state,
        selectedChannelId: null,
        archivedTab: action.payload,
      };
    case 'SET_DRAWER_DATA':
      return {
        ...state,
        isDrawerVisible: action.payload.isDrawerVisible,
        selectedCollaborator: action.payload.selectedCollaborator,
      };
    case 'SET_SELECTED_CHANNEL_ID':
      return {
        ...state,
        selectedChannelId: action.payload,
      };
    case 'SET_SELECTED_CHANNEL_ID_DRAWER':
      return {
        ...state,
        selectedChannelIdDrawer: action.payload,
      };
    case 'SET_HAS_MANY_MESSAGES':
      return {
        ...state,
        hasManyMessages: action.payload,
      };
    case 'SET_MOBILE_LIST_VISIBLE':
      return {
        ...state,
        isMobileListVisible: action.payload,
      };
    case 'ADD_COUNTERPARTS_HANDLES':
      return {
        ...state,
        counterpartsHandles: {
          ...state.counterpartsHandles,
          ...action.payload,
        },
      };
    case 'SET_REVIEWS_DATA':
      return {
        ...state,
        reviewsData: action.payload,
      };
    case 'RESET_STORE':
      return initialState;
    case 'SET_CHAT_SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.payload,
      };
    default:
      return state;
  }
}

export function ChatCtxProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <ChatCtx.Provider value={value}>{children}</ChatCtx.Provider>;
}
