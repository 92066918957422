import { useNavigate } from "react-router-dom";
import useTest from "./use-test";
import { eventNames, trackEvent } from "utils/instrumentation";

export default function useOpenPricing() {
  const navigate = useNavigate();
  const [, abTest] = useTest();
  const isVariant = abTest && abTest['11_24_pricing_redirect'] === 'variant';

  const track = (instrumentation) => {
    trackEvent(eventNames.PRICING_CLICKED, {
      ...(instrumentation || {}),
      origin: window.location.href,
    })
  }

  if (isVariant) {
    return (instrumentation) => {
      track(instrumentation);
      window.open('/pricing', '_blank')
    };
  } else {
    return (instrumentation) => {
      track(instrumentation);
      navigate('/pricing')
    };
  }
}

/*
import useOpenPricing from 'assets/CustomHooks/use-open-pricing';
const openPricing = useOpenPricing();
openPricing({ button_name: 'header', feature:'pricing' });
*/
