import { useEffect } from 'react';
import classNames from 'classnames';

import './PrintContainer.scss';

const usePrepareDocumentForPrinting = () => {
  useEffect(() => {
    const htmlTag = document.querySelector('html');
    htmlTag.classList.add('printable-page');
    return () => {
      htmlTag.classList.remove('printable-page');
    };
  }, []);
};

export default function PrintContainer({ children, ...props }) {
  const { isProfile } = props;

  usePrepareDocumentForPrinting();
  return <div className={classNames({ 'print-container': !isProfile })}>{children}</div>;
}
