const getAuthCookie = () => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; authToken=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return null;
};

const setAuthCookie = (token) => {
  if (process.env.NODE_ENV === 'production') {
    const ONE_YEAR_IN_SECONDS = 365 * 24 * 60 * 60;
    document.cookie = `authToken=${token};domain=.heepsy.com;secure;samesite=strict;max-age=${ONE_YEAR_IN_SECONDS};path=/`;
  }
};

const deleteAuthCookie = () => {
  const authCookie = getAuthCookie();
  if (authCookie) {
    const { hostname } = window.location;
    const domain = hostname === 'localhost' ? 'localhost' : '.heepsy.com';
    document.cookie = `authToken=;domain=${domain};secure;samesite=strict;path=/`;
  }
};

export { getAuthCookie, deleteAuthCookie, setAuthCookie };