const getStripeProductInfoFromResponse = (response) => {
  const stringifiedProductData = response?.headers?.get('plan');
  return stringifiedProductData ? JSON.parse(stringifiedProductData) : null;
};

const getPendingPaymentsFromResponse = (response) => {
  const hasPendingPaymentsHeader = response?.headers?.get('pending_payment');
  return hasPendingPaymentsHeader;
};

const shouldUpdateUserInfo = (response) => {
  return getStripeProductInfoFromResponse(response)?.id || getPendingPaymentsFromResponse(response);
};

const updateUserInfo = (response) => {
  let user = JSON.parse(localStorage.getItem('user'))?.user;
  if (user) {
    const stripe_product_info = getStripeProductInfoFromResponse(response);
    const hasPendingPaymentsHeader = getPendingPaymentsFromResponse(response);
    if (hasPendingPaymentsHeader) {
      user.pending_payments = hasPendingPaymentsHeader;
    }

    user.plan = stripe_product_info.id;
    user.stripe_product_info = stripe_product_info;

    if (user.verifications?.instagram)
      user.verifications.instagram.verified = response?.headers?.get('ig_verified') === 'true';
    if (user.verifications?.tiktok)
      user.verifications.tiktok.verified = response?.headers?.get('tt_verified') === 'true';
    if (user.verifications?.youtube)
      user.verifications.youtube.verified = response?.headers?.get('yt_verified') === 'true';

    localStorage.setItem('user', JSON.stringify({ user }));
  }
};

export { shouldUpdateUserInfo, updateUserInfo };
