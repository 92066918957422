import { memo, useState } from 'react';
import './environment-banner.scss';

const EnvironmentBanner = () => {
  const [bannerVisible, setBannerVisible] = useState(process.env.REACT_APP_DEV_ENV === 'true');
  return (
    <>
      {bannerVisible && (
        <div className="environment_banner -no-print">
          {process.env.REACT_APP_BRANCH_NAME !== 'development' ? '⚠️' : '✔️'}
          {process.env.REACT_APP_BRANCH_NAME}{' '}
          <span className="icon-cross environment_banner__close" onClick={() => setBannerVisible(false)}></span>
        </div>
      )}
    </>
  );
};

export default memo(EnvironmentBanner);
