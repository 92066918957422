import React, { useEffect, lazy, Suspense, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';

import logout from 'helpers/logout';
import stripeService from 'services/stripe.service';
import Loader from '../Loader/Loader';

const NavbarLogged = lazy(() => import('./NavbarLogged/NavbarLogged'));
const NavbarUnlogged = lazy(() => import('./NavbarUnlogged/NavbarUnlogged'));

const Navbar = ({ isErrorPage, setIsErrorPage }) => {
  const location = useLocation();
  const { t } = useTranslation('common');
  const token = JSON.parse(localStorage.getItem('user'))?.user?.token;
  
  const isLoginPage = ['/login'].includes(location.pathname);
  const isSignupPage = ['/signup'].includes(location.pathname);

  const [plan, setPlan] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (token) {
        const { exp } = jwt_decode(token);
        if (Date.now() >= exp * 1000) {
          logout(t('navbar_logged.expired_message'), true);
        } else {
          setPlan(await stripeService.getPlan());
        }
      }
    }
    fetchData();
  }, [token]); // eslint-disable-line

  return (
    <>
      {!token ? (
        <Suspense fallback={<Loader />}>
          <NavbarUnlogged
            isHomepage={['/login', '/signup'].includes(location.pathname)}
            isLoginPage={isLoginPage}
            isSignupPage={isSignupPage}
            isErrorPage={isErrorPage}
            setIsErrorPage={setIsErrorPage}
          />
        </Suspense>
      ) : (
        <Suspense fallback={<Loader />}>
          <NavbarLogged
            plan={plan}
            isErrorPage={isErrorPage}
            setIsErrorPage={setIsErrorPage}
          />
        </Suspense>
      )}
    </>
  );
};

export default React.memo(Navbar);
