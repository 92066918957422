import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

export function LogoutButton({ closeProfileMenu, logout }) {
  const { t } = useTranslation('common');
  
  return (
    <Button
      className="profile-menu__logout"
      type="default"
      onClick={() => {
        closeProfileMenu();
        logout(t('navbar_logged.dropdown.logout_success'), false);
      }}
    >
      {t('navbar_logged.dropdown.logout')}
    </Button>
  );
}
