import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, InputNumber } from 'antd';

import currencies from 'utils/currencies';
import networkToFees from 'utils/network-to-fees';
import { RequestNetworkCtx } from 'components/RequestNetworksModal/context/request-network-context';

const { Option } = Select;

export function CreatorInputs({ network }) {
  const { t } = useTranslation('common');

  const { state, dispatch } = useContext(RequestNetworkCtx);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex" style={{ gap: 8 }}>
        <div className="d-flex flex-1 flex-column">
          <p className="request-networks-modal__label">{t('signup_form.currency_label')}</p>
          <Select
            defaultValue={'usd'}
            showSearch
            on
            value={`${state[`currency_${network}`] || 'usd'}`}
            getPopupContainer={() => document.getElementById('signup-details')}
            onChange={(value) => dispatch({ type: `SET_CURRENCY_${network.toUpperCase()}`, payload: value })}
          >
            {Object.keys(currencies).map((k) => (
              <Option value={k} key={k}>
                {currencies[k]?.symbol} ({k.toUpperCase()})
              </Option>
            ))}
          </Select>
        </div>

        {networkToFees[network].map((a, i) => {
          return (
            <div className="d-flex flex-1 flex-column" key={i}>
              <p className="request-networks-modal__label">{t(`signup_form.${a.name || a.type}`)}</p>
              <InputNumber
                onChange={(val) =>
                  dispatch({ type: `SET_${a.type.toUpperCase()}_${network.toUpperCase()}`, payload: val })
                }
                min={0}
                max={10000000}
                className="w-100"
                step={1}
                value={`${state[`${a.type}_${network}`] || 0}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
