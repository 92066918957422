import React from 'react';
import { Navigate } from 'react-router-dom';
import defaultRedirectUrl from 'helpers/defaultRedirectUrl';

const PublicRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'))?.user;
  return !user?.token ? children : <Navigate to={defaultRedirectUrl(user?.account_type)} />;
};

export default PublicRoute;
