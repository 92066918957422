import classNames from 'classnames';
import { Dropdown, ConfigProvider } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DownIcon from 'images/Icons/icon_down_arrow.svg';

import userService from '../../../../services/users.service';

import './language-dropdown.scss';

export function LanguageDropdown({ isUnlogged, isMobile, isFooter }) {
  const { i18n } = useTranslation('common');
  const selectedLanguage = localStorage.getItem('lang') || 'en';

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    const user = JSON.parse(localStorage.getItem('user'))?.user;
    if (user?.token) {
      userService.updateLanguage(lang);
      user.lang = lang;
      localStorage.setItem('user', JSON.stringify({ user }));
    }
    document.documentElement.lang = lang;
  };

  const items = [
    {
      key: '1',
      onClick: () => setLanguage('en'),
      label: (
        <div
          className={classNames({
            'navbar__language-is-active': selectedLanguage === 'en',
            'navbar__language-is-inactive': selectedLanguage === 'es',
          })}
        >
          <CheckOutlined style={{ color: selectedLanguage === 'en' ? '#19202B' : 'transparent' }} className="mr-2" />{' '}
          English (EN)
        </div>
      ),
    },
    { type: 'divider' },
    {
      key: '2',
      onClick: () => setLanguage('es'),
      label: (
        <div
          className={classNames({
            'navbar__language-is-active': selectedLanguage === 'es',
            'navbar__language-is-inactive': selectedLanguage === 'en',
          })}
        >
          <CheckOutlined className="mr-2" style={{ color: selectedLanguage === 'es' ? '#19202B' : 'transparent' }} />{' '}
          Español (ES)
        </div>
      ),
    },
  ];

  return (
    <ConfigProvider theme={{ token: { fontSize: 16 } }}>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        className={classNames('navbar__language', {
          'mr-3': isUnlogged,
          '-not-logged-in': isUnlogged && !isFooter && !isMobile,
          '-is-mobile': isMobile,
          '-is-footer': isFooter,
          '-is-navbar': !isFooter && !isMobile,
        })}
        overlayClassName={classNames('navbar__language-menu', { '-is-footer': isFooter })}
      >
        <div
          className={classNames('mb-0 d-flex align-items-center', {
            'd-flex justify-content-between': isMobile || isFooter,
            'justify-content-center': !isMobile && !isFooter,
          })}
        >
          <div>
            {isMobile || isFooter
              ? `${selectedLanguage === 'en' ? 'English' : 'Español'}
            (${selectedLanguage?.toUpperCase() || 'EN'})`
              : selectedLanguage.toUpperCase()}
          </div>
          &nbsp;
          {isMobile ? (
            <div className="icon-mini-chevron down-icon" />
          ) : (
            <img src={DownIcon} alt="" className="ml-2 down-icon" />
          )}
        </div>
      </Dropdown>
    </ConfigProvider>
  );
}
