import { useMemo } from 'react';
import { manageTestsCookie, getAbTestCookie } from 'utils/abTest';
const abTests = require('../../utils/abTests.json')

export default function useTest() {
  manageTestsCookie();
  const abTest = getAbTestCookie();

  return useMemo(() => {
    return [abTests, abTest];
  }, [abTest]);
}
