import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import userService from 'services/users.service';

import useAfterAuthRedirections from 'assets/CustomHooks/use-after-auth-redirections';

export function LoginForm({ toggleForgot, isModal, toggleLogin, toggleVisibility, isLoginPage }) {
  const { t } = useTranslation('common');
  const selectedLanguage = localStorage.getItem('lang') || 'en';

  const location = useLocation();
  const navigate = useNavigate();

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { afterAuthRedirect } = useAfterAuthRedirections();

  const handleSubmission = (values) => {
    setEmailError(false);
    setPasswordError(false);

    const rootElem = document.getElementById('root');

    setIsLoading(true);
    userService.login(values.email, values.password, location.pathname + location.search).then(
      (user) => {
        message.success(t('login_page.success_message'));
        setIsLoading(false);
        toggleVisibility();

        rootElem.classList.remove('-no-vertical-menu');

        if (isModal && !location.pathname.includes('/password')) {
          window.location.reload();
        } else afterAuthRedirect(user?.user?.account_type);

        return true;
      },
      (err) => {
        console.error('err', err);
        if (err?.data?.errors?.account) {
          setIsLoading(false);
          setEmailError(true);
        } else {
          setIsLoading(false);
          setPasswordError(true);
        }
      },
    );
  };

  return (
    <Form
      id="moonio_login_form"
      onFinish={handleSubmission}
      layout="vertical"
      name="basic"
      onChange={() => {
        setEmailError(false);
        setPasswordError(false);
      }}
    >
      <div className="position-relative">
        <Form.Item
          name={'email'}
          label={t('login_page.form_email')}
          className={classNames({ '-has-error': emailError })}
          rules={[
            {
              type: 'email',
              required: true,
              message: t('forgot_password.required_error'),
            },
          ]}
        >
          <Input placeholder={t('login_page.form_email')} data-testid="signin-email" />
        </Form.Item>
        {emailError && (
          <span
            style={{ top: -20, position: 'relative' }}
            className={classNames('form-error-message -is-default', { '-is-spanish': selectedLanguage === 'es' })}
          >
            {t('login_page.verify_message')}
          </span>
        )}
      </div>

      <div
        className={classNames('position-relative', {
          'mb-2': passwordError,
          'mt-5': emailError && selectedLanguage === 'es',
        })}
      >
        <Form.Item
          label={t('login_page.form_pass')}
          name="password"
          className={classNames('mb-0', { '-has-error': passwordError })}
          rules={[
            {
              required: true,
              message: t('edit_password.required_error'),
            },
          ]}
        >
          <Input.Password placeholder={t('login_page.form_pass')} data-testid="signin-password" />
        </Form.Item>
        {passwordError && <span className="form-error-message -is-default">{t('login_page.pass_error')}</span>}
      </div>

      <Button type="link" onClick={toggleForgot} className="text-left w-auto p-0 mb-0">
        <span className="login-signup__link">{t('login_page.forgot_pass')}</span>
      </Button>

      <Form.Item className="mt-4">
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          {t('login_page.buttons.login')}
        </Button>
      </Form.Item>

      <div className="d-flex align-items-center justify-content-center">
        <span className="login-signup__small">{t('login_page.info')}</span>
        <Button
          type="link"
          onClick={() => {
            if (isModal) {
              toggleLogin();
            } else {
              toggleVisibility();
              if (isLoginPage) {
                navigate('/signup');
              }
            }
          }}
          className="w-auto p-0 mb-0 login-signup__btn"
          data-testid="login-submit"
        >
          <span className="login-signup__link login-signup__link-blue">
            <strong>{t('login_page.buttons.signup')}</strong>
          </span>
        </Button>
      </div>
    </Form>
  );
}
