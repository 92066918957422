export default function pushEventToGtmDataLayer(eventName, variables = {}) {
  try {
    window.dataLayer = window.dataLayer || [];
    const isExistingEvent =
      window.dataLayer.find(
        (a) =>
          ['Subscription Created', 'Subscription Upgraded', 'brands_agencies_sign_up'].includes(a.event) &&
          a.event === eventName,
      ) || window.dataLayer.find((a) => /^Subscription Created [A-Z]$/.test(a.event) && a.event === eventName);
    if (isExistingEvent) {
      return;
    } else {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  } catch {
    console.log('Error pushing to dataLayer');
  }
}
