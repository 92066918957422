import { createContext, useReducer, useContext, useMemo } from 'react';
import { Form } from 'antd';
import { DEFAULT_CREATORS_TABLE_COLUMNS, DEFAULT_REPORT_CURRENCY, DEFAULT_OVERVIEW_METRICS } from '../constants';

const useForm = Form.useForm;

const initialState = {
  reports: [],
  report: null,
  loadingReports: true,
  loadingReport: false,
  fetchingReportPreview: false,
  filtersOptions: {
    pipelines: [],
  },
  filtersOptionsFetched: false,
  filters: {
    pipelines: [],
    stages: [],
    networks: [],
    created_at: { type: 'current_month' },
    currency: null,
  },
  editReportTableColumnsModalIsOpen: false,
  editOverviewMetricsModalIsOpen: false,
  createReportModalIsOpen: false,
  deleteReportModalIsOpen: false,
  reportHasUnsavedFilters: false,
  reportHasBeenCreated: false,
  reportOnboardingVisible: false,
  creatorsTableColumns: DEFAULT_CREATORS_TABLE_COLUMNS,
  reportOverviewMetrics: DEFAULT_OVERVIEW_METRICS,
  reportCurrency: DEFAULT_REPORT_CURRENCY,
  isEdit: false
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_REPORTS':
      return {
        ...state,
        reports: payload,
      };
    case 'ADD_REPORT':
      return {
        ...state,
        reports: [payload, ...state.reports],
      };
    case 'DELETE_REPORT':
      return {
        ...state,
        //...pick(initialState, ['report', 'reportCurrency', 'creatorsTableColumns', 'reportOverviewMetrics', 'filters']),
        reports: state.reports.filter((report) => report.id !== payload),
      };
    case 'SET_REPORT':
      return {
        ...state,
        report: payload,
      };
    case 'SET_REPORT_DATA':
      return {
        ...state,
        report: {
          ...state.report,
          data: payload,
        },
      };
    case 'SET_LOADING_REPORTS':
      return {
        ...state,
        loadingReports: payload,
      };
    case 'SET_LOADING_REPORT':
      return {
        ...state,
        loadingReport: payload,
      };
    case 'SET_FETCHING_REPORT_PREVIEW':
      return {
        ...state,
        fetchingReportPreview: payload,
      };
    case 'RESET_FILTERS':
      return {
        ...state,
        filters: initialState.filters,
      };
    case 'SET_FILTERS_OPTIONS':
      return {
        ...state,
        filtersOptions: payload,
        filtersOptionsFetched: true,
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: payload,
      };
    case 'SET_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.key]: payload.value,
        },
      };
    case 'SET_CREATORS_TABLE_COLUMNS':
      return {
        ...state,
        creatorsTableColumns: payload,
      };
    case 'SET_REPORT_OVERVIEW_METRICS':
      return {
        ...state,
        reportOverviewMetrics: payload,
      };
    case 'SET_REPORT_CURRENCY':
      return {
        ...state,
        reportCurrency: payload,
      };
    case 'OPEN_CREATE_REPORT_MODAL':
      return {
        ...state,
        createReportModalIsOpen: true,
        isEdit: payload,
      };
    case 'CLOSE_CREATE_REPORT_MODAL':
      return {
        ...state,
        createReportModalIsOpen: false,
        isEdit: false,
      };
    case 'OPEN_EDIT_REPORT_OVERVIEW_METRICS_MODAL':
      return {
        ...state,
        editOverviewMetricsModalIsOpen: true,
      };
    case 'CLOSE_EDIT_REPORT_OVERVIEW_METRICS_MODAL':
      return {
        ...state,
        editOverviewMetricsModalIsOpen: false,
      };
    case 'OPEN_DELETE_REPORT_MODAL':
      return {
        ...state,
        deleteReportModalIsOpen: true,
      };
    case 'CLOSE_DELETE_REPORT_MODAL':
      return {
        ...state,
        deleteReportModalIsOpen: false,
      };
    case 'OPEN_EDIT_CREATORS_TABLE_COLUMNS_MODAL':
      return {
        ...state,
        editReportTableColumnsModalIsOpen: true,
      };
    case 'CLOSE_EDIT_CREATORS_TABLE_COLUMNS_MODAL':
      return {
        ...state,
        editReportTableColumnsModalIsOpen: false,
      };
    case 'SET_REPORT_HAS_UNSAVED_FILTERS':
      return {
        ...state,
        reportHasUnsavedFilters: payload,
      };
    case 'EDIT_REPORT_NAME':
      return {
        ...state,
        report: {
          ...state.report,
          title: payload.title,
        },
        reports: state.reports.map((report) => {
          return {
            ...report,
            title: report.id === payload.reportId ? payload.title : report.title,
          };
        }),
      };
    case 'REPORT_CREATED':
      return {
        ...state,
        reportHasBeenCreated: true,
      };
    case 'REPORT_ONBOARDING_VISIBLE':
      return {
        ...state,
        reportOnboardingVisible: payload,
      };
    case 'RESET_STORE':
      return initialState;

    default:
      return state;
  }
};

export const ReportingCtx = createContext();

export const ReportingCtxProvider = ({ children }) => {
  const [reportFiltersForm] = useForm();
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch, reportFiltersForm }), [state, reportFiltersForm]);
  return <ReportingCtx.Provider value={value}>{children}</ReportingCtx.Provider>;
};

export const useReportingCtx = () => {
  const ctx = useContext(ReportingCtx);
  if (ctx === undefined) {
    throw new Error('useReportingCtx must be used within a ReportingCtxProvider');
  }
  return ctx;
};
