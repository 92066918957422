/* eslint-disable no-undef */
import React, { useState, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';

import Loader from 'components/Loader/Loader';
import { LogoutButton } from './components/LogoutButton';
import { InfluLink } from './components/InfluLink';
import { BrandLink } from './components/BrandLink';
import { FindButton } from './components/FindButton';
import ProfileMenuModal from './components/ProfileMenuModal';
import profileIcon from 'images/illustrations/profile-icon.svg';
import inviteIcon from 'images/illustrations/invite-icon.svg';
import logout from 'helpers/logout';
import useMobileMenu from 'customHooks/useMobileMenu';
import useOpenPricing from 'assets/CustomHooks/use-open-pricing';

import {
  BarChartOutlined,
  PlayCircleOutlined,
  WalletOutlined,
  FolderOutlined,
  DollarOutlined,
} from '@ant-design/icons';

import './profile-menu.scss';

const AccountSettings = lazy(() => import('../UserProfile/AccountSettings/AccountSettings'));

const ProfileMenu = ({ closeProfileMenu }) => {
  const { t } = useTranslation('common');

  const { profileMenuIsOpen: profileMenuIsOpenMobile, closeProfileMenu: closeProfileMenuMobile } = useMobileMenu();
  const closeMenu = () => {
    closeProfileMenuMobile();
  };

  const settingsLink = {
    id: 2,
    link: '/my_settings',
    icon: '',
    text: t('navbar_logged.dropdown.settings'),
    modal: 'Settings',
  };

  const faqLink = {
    id: 6,
    link: '/faq',
    icon: '',
    text: t('navbar_logged.dropdown.help'),
    modal: 'SUPPORT & FAQ',
  };

  const contactLink = {
    id: 7,
    link: '/contact',
    icon: '',
    text: t('navbar_logged.dropdown.support'),
  };

  const invoicesLink = {
    id: 9,
    link: '/invoices',
    icon: '',
    text: t('navbar_logged.dropdown.invoices'),
  };

  const linksInflu = [settingsLink, invoicesLink, faqLink, contactLink];

  const firstLinksBrand = [settingsLink];
  const secondLinksBrands = [faqLink, contactLink];
  const openPricing = useOpenPricing();

  const [enableModal, setEnableModal] = useState('');
  const [isProfileMenuModalVisible, setProfileMenuModalVisibility] = useState(false);
  const account_type = JSON.parse(localStorage.getItem('user'))?.user?.account_type;
  const full_name = JSON.parse(localStorage.getItem('user'))?.user?.full_name;

  const toggleProfileMenuModal = () => {
    setProfileMenuModalVisibility(!isProfileMenuModalVisible);
  };

  const renderModalContent = () => {
    switch (enableModal) {
      case 'Settings':
        return <AccountSettings isModal />;
      default:
        return <></>;
    }
  };

  if (!profileMenuIsOpenMobile) {
    return null;
  }



  return (
    <>
      <div className="profile-menu profile-menu__isMobile">
        <div className="profile-menu__header">
          <h1 className="profile-menu__header-name">{full_name}</h1>
        </div>

        {account_type !== 'brand' && <Divider className="m-0" style={{ background: '#E8EBEC' }} />}

        {account_type === 'brand' && (
          <>
            <Link to="/pipelines" onClick={closeMenu}>
              <div className="profile-menu__item">
                <div className="d-flex justify-content-center">
                  <div className="profile-menu__item-img">
                    <FolderOutlined style={{ fontSize: 20, color: '#19202B' }} />
                  </div>
                  {t('navbar_logged.desktop.lists')}
                </div>
                <div className="icon-chevron" />
              </div>
            </Link>
            <Link to="/invites" onClick={closeMenu}>
              <div className="profile-menu__item">
                <div className="d-flex justify-content-start">
                  <div className="profile-menu__item-img">
                    <img src={inviteIcon} alt="" className="mr-3" />
                  </div>
                  {t('navbar_logged.dropdown.invites')}
                </div>
                <div className="icon-chevron" />
              </div>
            </Link>
            <Link to="/reports" onClick={closeMenu}>
              <div className="profile-menu__item">
                <div className="d-flex justify-content-start">
                  <div className="profile-menu__item-img">
                    <BarChartOutlined color="#19202B" style={{ fontSize: 20 }} />
                  </div>
                  {t('navbar_logged.desktop.reports')}
                </div>
                <div className="icon-chevron" />
              </div>
            </Link>
            <Link to="/media" onClick={closeMenu}>
              <div className="profile-menu__item">
                <div className="d-flex justify-content-start">
                  <div className="profile-menu__item-img">
                    <PlayCircleOutlined color="#19202B" style={{ fontSize: 20 }} />
                  </div>
                  {t('navbar_logged.desktop.media')}
                </div>
                <div className="icon-chevron" />
              </div>
            </Link>
            <Link to="/payments" onClick={closeMenu}>
              <div className="profile-menu__item">
                <div className="d-flex justify-content-start">
                  <div className="profile-menu__item-img">
                    <DollarOutlined style={{ fontSize: 20, color: '#19202B' }} />,
                  </div>
                  {t('navbar_logged.dropdown.payments_board')}
                </div>
                <div className="icon-chevron" />
              </div>
            </Link>
            <Divider className="m-0" style={{ background: '#E8EBEC' }} />
            <FindButton closeProfileMenu={closeMenu} />
            <Divider className="m-0" style={{ background: '#E8EBEC' }} />
          </>
        )}

        <Link to={'/my_profile'} onClick={closeMenu} id="menu_my-profile">
          <div className="profile-menu__item">
            <div className="d-flex justify-content-start">
              <div className="profile-menu__item-img">
                <img src={profileIcon} alt="" className="mr-3" />
              </div>
              {t('navbar_logged.dropdown.profile')}
            </div>
            <div className="icon-chevron" />
          </div>
        </Link>

        {account_type === 'influencer' &&
          linksInflu.map(
            (link) =>
              link && (
                <React.Fragment key={link.id}>
                  <InfluLink
                    closeProfileMenu={closeMenu}
                    setEnableModal={setEnableModal}
                    toggleProfileMenuModal={toggleProfileMenuModal}
                    link={link}
                  />
                </React.Fragment>
              ),
          )}
        {account_type === 'brand' &&
          firstLinksBrand.map(
            (link) =>
              link && (
                <React.Fragment key={link.id}>
                  <BrandLink closeProfileMenu={closeMenu} link={link} />
                </React.Fragment>
              ),
          )}
        {account_type === 'brand' && (
          <>
            <Link onClick={(e) => {
              e.preventDefault();
              openPricing({ button_name: 'mobile_navbar_pricing' })
              closeMenu();
              }}>
              <div className="profile-menu__item">
                <div className="d-flex justify-content-start">
                  <div className="profile-menu__item-img">
                    <WalletOutlined color="#19202B" style={{ fontSize: 20 }} />
                  </div>
                  {t('navbar_logged.dropdown.pricing')}
                </div>
                <div className="icon-chevron" />
              </div>
            </Link>
          </>
        )}
        <Divider className="m-0" style={{ background: '#E8EBEC' }} />
        {account_type === 'brand' &&
          secondLinksBrands.map(
            (link) =>
              link && (
                <React.Fragment key={link.id}>
                  <BrandLink closeProfileMenu={closeMenu} link={link} />
                </React.Fragment>
              ),
          )}
        <LogoutButton closeProfileMenu={closeMenu} logout={logout} />
      </div>
      <Suspense fallback={<Loader />}>
        <ProfileMenuModal
          visible={isProfileMenuModalVisible}
          toggleVisibility={toggleProfileMenuModal}
          title={enableModal}
          content={() => renderModalContent()}
        />
      </Suspense>
    </>
  );
};

export default React.memo(ProfileMenu);
