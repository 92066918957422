import { useMemo } from 'react';

export default function usePaymentsPendingInflu() {
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : {};

  return useMemo(() => {
    const hasPendingPayment = user?.pending_payments;

    return [hasPendingPayment];
  }, [user?.pending_payments]);
}
