import PropTypes from 'prop-types';
import React from 'react';

const OptedIcon = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 182 190"
    enableBackground="new 0 0 182 190"
    style={{ height: 26, width: 26 }}
    color={props.color}
  >
    <path
      fill="transparent"
      opacity="1.000000"
      stroke="none"
      d="
M106.000000,191.000000 
	C70.666672,191.000000 35.833336,191.000000 1.000005,191.000000 
	C1.000003,127.666672 1.000003,64.333344 1.000002,1.000013 
	C61.666656,1.000009 122.333313,1.000009 182.999969,1.000004 
	C182.999985,64.333321 182.999985,127.666634 183.000000,190.999969 
	C157.500000,191.000000 132.000000,191.000000 106.000000,191.000000 
M75.698257,12.488173 
	C74.935905,11.662706 74.198936,10.151190 73.407120,10.121895 
	C67.808212,9.914752 62.198059,10.011775 56.266918,10.011775 
	C54.452972,42.232750 52.673424,73.842743 50.880329,105.693390 
	C57.644630,105.693390 63.710217,105.693390 69.994362,105.693390 
	C70.275787,103.275200 70.640480,101.146233 70.754791,99.003899 
	C71.897903,77.580360 72.969604,56.152992 74.130325,34.730438 
	C74.516708,27.599302 75.101540,20.478920 75.698257,12.488173 
M107.271645,126.858101 
	C106.367737,144.727432 105.463829,162.596756 104.550926,180.643860 
	C111.060547,180.643860 117.271942,180.643860 123.818031,180.643860 
	C125.622154,148.704941 127.406876,117.109581 129.201401,85.340630 
	C122.322403,85.340630 116.265503,85.340630 109.927124,85.340630 
	C109.634804,89.300125 109.328545,92.933304 109.105301,96.571571 
	C108.504982,106.355301 107.940117,116.141212 107.271645,126.858101 
M82.275757,56.981197 
	C82.275757,62.564346 82.275757,68.147491 82.275757,73.832413 
	C110.573387,73.832413 138.496689,73.832413 166.660934,73.832413 
	C166.660934,66.840721 166.660934,60.129105 166.660934,53.529148 
	C166.022888,53.302128 165.720016,53.100338 165.416824,53.099857 
	C138.919647,53.057861 112.422241,52.991234 85.925972,53.134361 
	C84.728401,53.140827 83.541138,55.054127 82.275757,56.981197 
M32.500427,137.999527 
	C52.490673,137.999466 72.481415,138.059021 92.470261,137.891525 
	C94.154373,137.877411 97.208832,136.442261 97.278053,135.495590 
	C97.719757,129.454239 97.503021,123.364738 97.503021,117.329788 
	C69.336205,117.329788 41.307575,117.329788 13.314732,117.329788 
	C13.314732,124.362488 13.314732,130.951111 13.314732,137.999527 
	C19.579678,137.999527 25.540167,137.999527 32.500427,137.999527 
M66.809532,169.293701 
	C67.125229,162.423721 67.440918,155.553741 67.776566,148.249588 
	C61.917938,148.249588 56.618763,148.019501 51.369316,148.428207 
	C50.208141,148.518600 48.426277,150.791000 48.291203,152.190063 
	C47.623199,159.109024 47.375839,166.070435 47.058598,173.020447 
	C46.941647,175.582642 47.039726,178.154633 47.039726,180.742615 
	C53.654411,180.742615 59.566059,180.742615 65.720764,180.742615 
	C66.087166,177.083893 66.430130,173.659164 66.809532,169.293701 
M113.247711,20.823263 
	C113.247711,27.741007 113.247711,34.658752 113.247711,41.710884 
	C118.987938,41.710884 125.187607,41.710884 131.736145,41.710884 
	C132.244186,30.988367 132.733505,20.661068 133.224915,10.289670 
	C126.323723,10.289670 120.397987,10.289670 114.360855,10.289670 
	C113.976357,13.674870 113.623306,16.783184 113.247711,20.823263 
M27.627163,52.998508 
	C25.872326,52.998508 24.117487,52.998508 22.410662,52.998508 
	C22.410662,60.432369 22.410662,67.134338 22.410662,73.806061 
	C29.407598,73.806061 35.988037,73.806061 43.120781,73.806061 
	C43.482567,66.853577 43.829906,60.178703 44.203541,52.998497 
	C38.489864,52.998497 33.542141,52.998497 27.627163,52.998508 
M146.506165,138.001480 
	C150.245544,138.001480 153.984909,138.001480 157.721237,138.001480 
	C157.721237,130.616852 157.721237,124.039581 157.721237,117.226517 
	C150.743683,117.226517 144.049011,117.226517 136.849319,117.226517 
	C136.502121,124.216393 136.170532,130.891937 135.817383,138.001480 
	C139.599350,138.001480 142.562317,138.001480 146.506165,138.001480 
z"
    />
    <path
      fill={props.color || '#95BB94'}
      opacity="1.000000"
      stroke="none"
      d="
M75.646957,12.920898 
	C75.101540,20.478920 74.516708,27.599302 74.130325,34.730438 
	C72.969604,56.152992 71.897903,77.580360 70.754791,99.003899 
	C70.640480,101.146233 70.275787,103.275200 69.994362,105.693390 
	C63.710217,105.693390 57.644630,105.693390 50.880329,105.693390 
	C52.673424,73.842743 54.452972,42.232750 56.266918,10.011775 
	C62.198059,10.011775 67.808212,9.914752 73.407120,10.121895 
	C74.198936,10.151190 74.935905,11.662706 75.646957,12.920898 
z"
    />
    <path
      fill={props.color || '#95BB94'}
      opacity="1.000000"
      stroke="none"
      d="
M107.316414,126.392181 
	C107.940117,116.141212 108.504982,106.355301 109.105301,96.571571 
	C109.328545,92.933304 109.634804,89.300125 109.927124,85.340630 
	C116.265503,85.340630 122.322403,85.340630 129.201401,85.340630 
	C127.406876,117.109581 125.622154,148.704941 123.818031,180.643860 
	C117.271942,180.643860 111.060547,180.643860 104.550926,180.643860 
	C105.463829,162.596756 106.367737,144.727432 107.316414,126.392181 
z"
    />
    <path
      fill={props.color || '#93BA92'}
      opacity="1.000000"
      stroke="none"
      d="
M82.312416,56.530235 
	C83.541138,55.054127 84.728401,53.140827 85.925972,53.134361 
	C112.422241,52.991234 138.919647,53.057861 165.416824,53.099857 
	C165.720016,53.100338 166.022888,53.302128 166.660934,53.529148 
	C166.660934,60.129105 166.660934,66.840721 166.660934,73.832413 
	C138.496689,73.832413 110.573387,73.832413 82.275757,73.832413 
	C82.275757,68.147491 82.275757,62.564346 82.312416,56.530235 
z"
    />
    <path
      fill={props.color || '#93BA92'}
      opacity="1.000000"
      stroke="none"
      d="
M32.000542,137.999527 
	C25.540167,137.999527 19.579678,137.999527 13.314732,137.999527 
	C13.314732,130.951111 13.314732,124.362488 13.314732,117.329788 
	C41.307575,117.329788 69.336205,117.329788 97.503021,117.329788 
	C97.503021,123.364738 97.719757,129.454239 97.278053,135.495590 
	C97.208832,136.442261 94.154373,137.877411 92.470261,137.891525 
	C72.481415,138.059021 52.490673,137.999466 32.000542,137.999527 
z"
    />
    <path
      fill={props.color || '#97BC96'}
      opacity="1.000000"
      stroke="none"
      d="
M66.791313,169.764069 
	C66.430130,173.659164 66.087166,177.083893 65.720764,180.742615 
	C59.566059,180.742615 53.654411,180.742615 47.039726,180.742615 
	C47.039726,178.154633 46.941647,175.582642 47.058598,173.020447 
	C47.375839,166.070435 47.623199,159.109024 48.291203,152.190063 
	C48.426277,150.791000 50.208141,148.518600 51.369316,148.428207 
	C56.618763,148.019501 61.917938,148.249588 67.776566,148.249588 
	C67.440918,155.553741 67.125229,162.423721 66.791313,169.764069 
z"
    />
    <path
      fill={props.color || '#95BB94'}
      opacity="1.000000"
      stroke="none"
      d="
M113.258987,20.357380 
	C113.623306,16.783184 113.976357,13.674870 114.360855,10.289670 
	C120.397987,10.289670 126.323723,10.289670 133.224915,10.289670 
	C132.733505,20.661068 132.244186,30.988367 131.736145,41.710884 
	C125.187607,41.710884 118.987938,41.710884 113.247711,41.710884 
	C113.247711,34.658752 113.247711,27.741007 113.258987,20.357380 
z"
    />
    <path
      fill={props.color || '#00A742'}
      opacity="1.000000"
      stroke="none"
      d="
M28.110790,52.998505 
	C33.542141,52.998497 38.489864,52.998497 44.203541,52.998497 
	C43.829906,60.178703 43.482567,66.853577 43.120781,73.806061 
	C35.988037,73.806061 29.407598,73.806061 22.410662,73.806061 
	C22.410662,67.134338 22.410662,60.432369 22.410662,52.998508 
	C24.117487,52.998508 25.872326,52.998508 28.110790,52.998505 
z"
    />
    <path
      fill={props.color || '#00A742'}
      opacity="1.000000"
      stroke="none"
      d="
M146.015717,138.001480 
	C142.562317,138.001480 139.599350,138.001480 135.817383,138.001480 
	C136.170532,130.891937 136.502121,124.216393 136.849319,117.226517 
	C144.049011,117.226517 150.743683,117.226517 157.721237,117.226517 
	C157.721237,124.039581 157.721237,130.616852 157.721237,138.001480 
	C153.984909,138.001480 150.245544,138.001480 146.015717,138.001480 
z"
    />
  </svg>
);

OptedIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default OptedIcon;
