import { parseEngagement } from "assets/helpers/helpers";
import formatNumber from "./formatNumber";

const COLORS = { red: '#DC545F', orange: '#FFA947', green: '#19A35A', gray: '#C8CED4' };

/////////////////////////////////////////////////

export const ENGAGEMENT_LIMITS = {
  ig: [0.1, 0.4, 1.5, 20],
  tt: [0.1, 0.3, 1.2, 20],
  yt: [0.01, 0.2, 1, 20],
}
const ENGAGEMENT_COLORS = [COLORS['red'], COLORS['orange'], COLORS['green'], COLORS['green'], COLORS['gray']];
const ENGAGEMENT_WORDS = ['Very low', 'Low', 'Average', 'High', 'Suspiciously high'];

/////////////////////////////////////////////////

const REACTIONS_LIMITS = {
  ig: [10, 60, 250, 2000],
  tt: [14, 55, 200, 10000],
  yt: [2, 15, 55, 1000],
}
const REACTIONS_COLORS = [COLORS['red'], COLORS['orange'], COLORS['green'], COLORS['green'], COLORS['green']];
const REACTIONS_WORDS = ['Very low', 'Low', 'Average', 'High', 'Very high'];

/////////////////////////////////////////////////

const ESTIMATED_REACH_LIMITS = {
  ig: [10, 100, 400, 3000],
  tt: [100, 500, 2500, 150000],
  yt: [60, 500, 2000, 50000],
}
const ESTIMATED_REACH_COLORS = [COLORS['red'], COLORS['orange'], COLORS['green'], COLORS['green'], COLORS['green']];
const ESTIMATED_REACH_WORDS = ['Very low', 'Low', 'Average', 'High', 'Very high'];

/////////////////////////////////////////////////

const RECENCY_LIMITS = {
  ig: [7, 21, 60],
  tt: [7, 21, 60],
  yt: [7, 21, 60],
}
const RECENCY_COLORS = [COLORS['green'], COLORS['green'], COLORS['orange'], COLORS['red']];
const RECENCY_WORDS = ['Very recently', 'Recently', 'Average', 'Not recently'];

/////////////////////////////////////////////////

const POSTS_LIMITS = {
  ig: [0.05, 0.4, 1.75, 10],
  tt: [0.17, 1.5, 10, 42],
  yt: [0.03, 0.1, 0.15, 3],
}
const POSTS_COLORS = [COLORS['red'], COLORS['orange'], COLORS['green'], COLORS['green'], COLORS['green']];
const POSTS_WORDS = ['Very low', 'Low', 'Average', 'High', 'Very high'];

/////////////////////////////////////////////////

const CHANNEL_VIEWS_LIMITS = [50000, 500000, 2000000, 15000000]
const CHANNEL_VIEWS_COLORS = [COLORS['red'], COLORS['orange'], COLORS['green'], COLORS['green'], COLORS['green']];
const CHANNEL_VIEWS_WORDS = ['Very low', 'Low', 'Average', 'High', 'Very high'];

/////////////////////////////////////////////////

const AVERAGE_VIEWS_LIMITS = [50, 400, 2500, 50000]
const AVERAGE_VIEWS_COLORS = [COLORS['red'], COLORS['orange'], COLORS['green'], COLORS['green'], COLORS['green']];
const AVERAGE_VIEWS_WORDS = ['Very low', 'Low', 'Average', 'High', 'Very high'];

/////////////////////////////////////////////////

const clipBenchmark = (benchmark) => {
  if (benchmark > 97) return 97
  if (benchmark < 3) return 3
  return benchmark
}

export const parseEngagementInsights = (t, user) => {
  if (!user.engagement_rate) {
    return {
      title: 'Engagement',
    }
  }
  const net = user.profile_id.split('_')[0]
  const benchmark = clipBenchmark(user.engagement_rate_benchmark)
  const grade = ENGAGEMENT_LIMITS[net].filter((x) => user.engagement_rate > x).length;

  const response = {
    title: 'Engagement',
    tooltip: t('profile_content.engagement_tooltip'),
    info: t('profile_content.higher_than', { benchmark }),
    word: t(`profile_content.${ENGAGEMENT_WORDS[grade]}`),
    value: parseEngagement(user.engagement_rate, net),
    color: ENGAGEMENT_COLORS[grade]
  }
  return response
}

export const parseReactionsInsights = (t, user) => {
  if (!user.reactions) {
    return {
      title: t('profile_content.reaction_content'),
    }
  }
  const net = user.profile_id.split('_')[0]
  const benchmark = clipBenchmark(user.reactions_benchmark)
  const grade = REACTIONS_LIMITS[net].filter((x) => user.reactions > x).length;

  const response = {
    title: t('profile_content.reaction_content'),
    info: t('profile_content.higher_than', { benchmark }),
    word: t(`profile_content.${REACTIONS_WORDS[grade]}`),
    likes: user.average_reactions_photo_likes,
    comments: user.average_reactions_photo_comments,
    color: REACTIONS_COLORS[grade]
  }
  return response
}

export const parseEstimatedReachInsights = (t, user) => {
  if (!user.estimated_reach) {
    return {
      title: t('profile_content.estimated_reach_title'),
    }
  }
  const net = user.profile_id.split('_')[0]
  const benchmark = clipBenchmark(user.estimated_reach_benchmark)
  const grade = ESTIMATED_REACH_LIMITS[net].filter((x) => user.estimated_reach > x).length;

  const response = {
    title: t('profile_content.estimated_reach_title'),
    info: t('profile_content.higher_than', { benchmark }),
    word: t(`profile_content.${ESTIMATED_REACH_WORDS[grade]}`),
    value: formatNumber(user.estimated_reach),
    color: ESTIMATED_REACH_COLORS[grade]
  }
  return response
}

export const parseRecencyInsights = (t, user) => {
  if (user.last_post === null) {
    return {
      title: t('profile_content.recency'),
    }
  }
  const net = user.profile_id.split('_')[0]
  const benchmark = clipBenchmark(100 - user.last_post_benchmark)
  const grade = RECENCY_LIMITS[net].filter((x) => user.last_post > x).length;

  let value
  if (user.last_post === 0) {
    value = t('dates.today')
  } else if (user.last_post > 60){
    value = `>60 ${t(`profile_content.days`)}`
  } else {
    value = `${user.last_post} ${t(`profile_content.day${user.last_post > 1 ? 's' : ''}`)}`
  }

  const response = {
    title: t('profile_content.recency'),
    tooltip: t('profile_content.recency_tooltip'),
    info: t('profile_content.more_recently_than', { benchmark }),
    word: t(`profile_content.${RECENCY_WORDS[grade]}`),
    value,
    color: RECENCY_COLORS[grade],
  }
  return response
}

export const parsePostsPerWeekInsights = (t, user) => {
  if (!user.posts_per_week) {
    return {
      title: t('profile_content.post_week'),
    }
  }
  const net = user.profile_id.split('_')[0]
  const benchmark = clipBenchmark(user.posts_per_week_benchmark)
  const grade = POSTS_LIMITS[net].filter((x) => user.posts_per_week > x).length;

  const response = {
    title: t('profile_content.post_week'),
    tooltip: t('profile_content.post_tooltip'),
    info: t('profile_content.higher_than', { benchmark }),
    word: t(`profile_content.${POSTS_WORDS[grade]}`),
    value: user.posts_per_week,
    color: POSTS_COLORS[grade],
  }
  return response
}

export const parseChannelViews = (t, user) => {
  if (!user.channel_views) {
    return {
      title: t('profile_content.total_video_views_count_title'),
    }
  }
  const benchmark = clipBenchmark(user.channel_views_benchmark)
  const grade = CHANNEL_VIEWS_LIMITS.filter((x) => user.channel_views > x).length;

  const response = {
    title: t('profile_content.total_video_views_count_title'),
    info: t('profile_content.higher_than', { benchmark }),
    word: t(`profile_content.${CHANNEL_VIEWS_WORDS[grade]}`),
    value: formatNumber(user.channel_views),
    color: CHANNEL_VIEWS_COLORS[grade],
  }
  return response
}

export const parseAverageViews = (t, user) => {
  if (!user.average_views) {
    return {
      title: t('profile_content.average_video_views_count_title'),
    }
  }
  const benchmark = clipBenchmark(user.average_views_benchmark)
  const grade = AVERAGE_VIEWS_LIMITS.filter((x) => user.average_views > x).length;

  const response = {
    title: t('profile_content.average_video_views_count_title'),
    info: t('profile_content.higher_than', { benchmark }),
    word: t(`profile_content.${AVERAGE_VIEWS_WORDS[grade]}`),
    value: formatNumber(user.average_views),
    color: AVERAGE_VIEWS_COLORS[grade],
  }
  return response
}