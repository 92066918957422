import moment from 'moment';
import crypto from 'crypto';
import { ENGAGEMENT_LIMITS } from 'helpers/publicationInsights';

export function renderColorClass(wording) {
  if (wording === 'Very low') return 'red';
  if (wording === 'Low') return 'orange';
  if (['Average', 'High', 'Very high'].includes(wording)) return 'green';
  if (wording === 'Suspiciously high') return 'suspicious';
  return '';
}

export function reformatCateg(categoriesList, val) {
  const cat = categoriesList?.filter(function (x) {
    return x[1] === val;
  });
  if (cat?.length > 0) {
    return cat[0][0];
  } else {
    return val;
  }
}

export function checkIsNotEmpty(val) {
  return val !== null && val !== undefined && !/N\/A/i.test(val) && val !== '';
}

export const showIntercom = () => {
  if (typeof window.Intercom !== 'undefined') {
    window.Intercom('show');
  }
};

export const hideIntercomButton = () => {
  if (typeof window.Intercom !== 'undefined') {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }
};

export const showIntercomButton = () => {
  if (typeof window.Intercom !== 'undefined') {
    window.Intercom('update', {
      hide_default_launcher: false,
    });
  }
};

export function readCookies(name) {
  var nameEQ = name + '=';
  const cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var c = cookies[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) {
      return JSON.parse(c.substring(nameEQ.length, c.length));
    }
  }
  return null;
}

export function deleteCookies(name, path, domain) {
  if (readCookies(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}

export function removeContinent(location) {
  const continents = ['Europe,', 'Africa,', 'North America,', 'South America,', 'Asia,', 'Oceania,'];
  let continent = '';
  continents.forEach((con) => {
    if (location?.includes(con)) continent = con;
  });
  if (continent.length > 0) return location.replace(continent, '').trimStart();
  else return location;
}

export const locationsQuickFix = (location) => location?.replace('Dubay', 'Dubai');

export const formatChatDate = (date, t) => {
  const today = moment(date).isSame(new Date(), 'day');
  const yesterday = moment(date).isSame(new Date().setDate(new Date().getDate() - 1), 'day');
  const sameWeek = moment(date).isSame(new Date(), 'week');
  const sameYear = moment(date).isSame(new Date(), 'year');

  if (today) {
    return t('dates.today');
  } else if (yesterday) {
    return t('dates.yesterday');
  } else if (sameWeek) {
    return moment(date).format('dddd');
  } else if (sameYear) {
    return moment(date).format('dddd, DD MMM');
  } else {
    return moment(date).format('dddd, DD MMM YYYY');
  }
};

export const encryptAuth = (text) => {
  const cipher = crypto.createCipheriv(
    'aes-256-ctr',
    process.env.REACT_APP_ENCRYPTION_KEY,
    Buffer.from(process.env.REACT_APP_ENCRYPTION_IV, 'hex'),
  );
  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
  return encrypted.toString('hex');
};

/**
 * Transform an array like [{profile_id:'1',...someData},{profile_id:'2',...someData},{profile_id:'3',...someData},...]
 * in the following object {'1':someData,'2':someData,....} by calling objectsArrayToObject(array,'profile_id').
 * The key parameter must be a unique identifer for each element of the array
 *
 *
 */
export const objectsArrayToObject = (array, key) => {
  return Object.fromEntries((array || []).map(({ [key]: id, ...data }) => [id, data]));
};

export const parseEngagement = (engagementRate, net='ig') => {
  const min = ENGAGEMENT_LIMITS[net][0]
  const max = ENGAGEMENT_LIMITS[net][ENGAGEMENT_LIMITS[net].length - 1]
  const showTwoDecimals = engagementRate <= 0.1

  return parseFloat(engagementRate) > max
    ? `>${max}%`
    : parseFloat(engagementRate) < min
    ? `<${min}%`
    : `${parseFloat(engagementRate).toFixed(showTwoDecimals ? 2 : 1)}%`
}

export const isUnlockedProfile = (unlocked_at) => {
  const todayMinus30Days = new Date();
  todayMinus30Days.setDate(todayMinus30Days.getDate() - 30);
  return !!unlocked_at && new Date(unlocked_at) > todayMinus30Days;
};
