import interceptor from './interceptor.service';
import { hardRefresh, shouldUpdate } from 'utils/hardRefresh';
import { updateUserInfo, shouldUpdateUserInfo } from 'utils/userInfo';

const config = { apiUrl: process.env.REACT_APP_API_URL };

function handleResponse(response) {
  if (shouldUpdate(response)) hardRefresh();
  if (shouldUpdateUserInfo(response)) updateUserInfo(response);
  switch (response.status) {
    case 500:
      return {status: 500, data: null};
    case 200:
      return response.text().then((text) => {
        const data = text && JSON.parse(text);
        return data;
      });
    default:
      return {status: response?.status, data: null};
  }
}

function createSurvey(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
    body: JSON.stringify(data),
  };

  return interceptor(`${config.apiUrl}/api/survey/create`, requestOptions)
    .then(handleResponse)
};

function showSurvey() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${JSON.parse(localStorage.getItem('user'))?.user?.token}`,
    },
  };

  return interceptor(`${config.apiUrl}/api/survey/show`, requestOptions)
    .then(handleResponse)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createSurvey,
  showSurvey
};
