import PropTypes from 'prop-types';
import React from 'react';

const MarketplaceIcon = (props) => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      verticalAlign: 'middle',
      color: props.color,
      ...props.style,
    }}
  >
    <path
      d="M20.3008 11.242V18H21.3008V20H1.30078V18H2.30078V11.242C1.68515 10.8311 1.18049 10.2745 0.831601 9.62176C0.482708 8.96899 0.300374 8.24017 0.300782 7.5C0.300782 6.673 0.524782 5.876 0.933782 5.197L3.64578 0.5C3.73355 0.347985 3.85978 0.221749 4.0118 0.133981C4.16381 0.0462129 4.33625 4.45922e-06 4.51178 0H18.0908C18.2663 4.45922e-06 18.4388 0.0462129 18.5908 0.133981C18.7428 0.221749 18.869 0.347985 18.9568 0.5L21.6588 5.182C22.2553 6.17287 22.4471 7.35553 22.1942 8.48414C21.9413 9.61275 21.2632 10.6005 20.3008 11.242ZM18.3008 11.972C17.6132 12.0491 16.9171 11.9665 16.2667 11.7307C15.6163 11.4948 15.0291 11.1119 14.5508 10.612C14.131 11.0511 13.6266 11.4005 13.068 11.6393C12.5094 11.878 11.9082 12.001 11.3008 12.001C10.6935 12.0013 10.0924 11.8786 9.53381 11.6402C8.97523 11.4018 8.47074 11.0527 8.05078 10.614C7.57241 11.1138 6.98516 11.4964 6.33473 11.7321C5.68431 11.9678 4.98827 12.0503 4.30078 11.973V18H18.3008V11.973V11.972ZM5.08978 2L2.65678 6.213C2.42036 6.79714 2.41326 7.44903 2.63691 8.03818C2.86056 8.62733 3.29846 9.11029 3.86296 9.39039C4.42747 9.6705 5.07693 9.72708 5.68136 9.54882C6.2858 9.37055 6.80062 8.9706 7.12278 8.429C7.45778 7.592 8.64278 7.592 8.97878 8.429C9.16408 8.89342 9.48438 9.2916 9.89831 9.5721C10.3122 9.85261 10.8008 10.0025 11.3008 10.0025C11.8008 10.0025 12.2893 9.85261 12.7033 9.5721C13.1172 9.2916 13.4375 8.89342 13.6228 8.429C13.9578 7.592 15.1428 7.592 15.4788 8.429C15.6086 8.74845 15.803 9.0376 16.0499 9.27828C16.2968 9.51895 16.5909 9.70595 16.9135 9.82752C17.2362 9.94909 17.5805 10.0026 17.9248 9.98468C18.2692 9.96677 18.6061 9.87782 18.9144 9.72343C19.2227 9.56903 19.4957 9.35253 19.7163 9.08753C19.9369 8.82253 20.1003 8.51477 20.1963 8.18357C20.2922 7.85238 20.3186 7.50493 20.2737 7.16305C20.2289 6.82118 20.1138 6.49227 19.9358 6.197L17.5108 2H5.09078H5.08978Z"
      fill={props.color}
    />
  </svg>
);

MarketplaceIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default MarketplaceIcon;
