import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

export function HeaderSignupBtns({ toggleSignup, setDropdownOpen, toggleLogin }) {
  const { t } = useTranslation('common');

  return (
    <div className="home-header__dropdown-buttons">
      <Button
        type="primary"
        onClick={() => {
          toggleSignup();
          setDropdownOpen(false);
        }}
      >
        {t('home_headers.signup')}
      </Button>
    </div>
  );
}
